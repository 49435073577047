import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Container,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  ModalHeader,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from 'react-loader-spinner';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';
import validator from 'validator';

function TableRow({
  validity_item,
  removeValidityPlan,
  setCurrentSelectedValidity,
  setEditValidityModalOpen,
  hideRow,
}) {
  if (hideRow) return null;
  return (
    <tr>
      <th>{validity_item.validity_code}</th>
      <td>{validity_item.validity_name}</td>
      <td>{validity_item.total_cost}</td>
      <td>{validity_item.total_days}</td>
      <td>
        <Button
          color='primary'
          size='sm'
          className='waves-effect waves-light'
          onClick={() => {
            setCurrentSelectedValidity(validity_item);
            setEditValidityModalOpen(true);
          }}
        >
          Edit
        </Button>
        <Button
          color='danger'
          size='sm'
          className='waves-effect waves-light ml-3'
          onClick={() => {
            removeValidityPlan(validity_item.validity_code);
          }}
        >
          Remove
        </Button>
      </td>
    </tr>
  );
}

const ValiditySetting = () => {
  const { city_code, city_id } = useParams();
  const [breadcrumbItems] = useState([
    { title: 'City', link: '/' },
    { title: 'City Details', link: '#' },
    { title: city_code, link: `/city/${city_id}/${city_code}` },
    { title: 'Validity Settings', link: '#' },
  ]);
  const history = useHistory();

  const [fetchingSetting, setFetchingSetting] = useState(true);
  const [validityEntries, setValidityEntries] = useState([]);
  const [addingNewValidityEntry, setAddingNewValidityEntry] = useState(false);
  const [newValidityModalOpen, setNewValidityModalOpen] = useState(false);
  const [currentSelectedValidity, setCurrentSelectedValidity] = useState(null);
  const [editValidityModalOpen, setEditValidityModalOpen] = useState(false);
  const [editingValidityPlan, setEditingValidityPlan] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');

  const updateValiditySetting = async (event, values) => {
    setEditingValidityPlan(true);
    try {
      setAuthToken();
      const { data: response } = await sendRequest.put(
        `/city/${city_id}/setting/validity/${currentSelectedValidity.validity_code}`,
        {
          validity_name: values.validity_name,
          total_days: Number(values.total_days),
          total_cost: Number(values.total_cost),
        }
      );
      setValidityEntries((st) =>
        st.map((k) => {
          if (k.validity_code === response.data.validity_code) {
            return response.data;
          } else return k;
        })
      );
      resetEditModal();
    } catch (e) {
      console.log(e);
    }
    setEditingValidityPlan(false);
  };

  const fetchValiditySetting = async () => {
    setFetchingSetting(true);
    try {
      setAuthToken();
      const { data: validity_data } = await sendRequest.get(
        `/city/${city_id}/setting?setting_type=validity`
      );
      setValidityEntries(validity_data);
    } catch (e) {
      history.push(`/city/${city_id}/${city_code}`);
      console.log(e);
    }
    setFetchingSetting(false);
  };

  useEffect(() => {
    fetchValiditySetting();
    // eslint-disable-next-line
  }, []);

  const addNewValidityPack = async (event, values) => {
    setAddingNewValidityEntry(true);

    try {
      setAuthToken();
      const { data: response } = await sendRequest.post(`/city/${city_id}/setting/validity`, {
        validity_name: values.validity_name,
        total_days: Number(values.total_days),
        total_cost: Number(values.total_cost),
      });

      setValidityEntries((st) => [...st, response.data]);
      setAddingNewValidityEntry(false);
      setNewValidityModalOpen(false);
      resetSearch();
    } catch (e) {
      console.log(e);
    }
  };

  const uniqueNameValidatorForValidity =
    (operation_type, validity_code) => async (value, ctx, input, cb) => {
      let trimmedValue = value?.trim().toLowerCase() || '';
      let isUnique =
        operation_type === 'ADD'
          ? validityEntries.every((it) => it.validity_name.toLowerCase().trim() !== trimmedValue)
          : !validityEntries.some(
              (it) =>
                it.validity_name.toLowerCase().trim() === trimmedValue &&
                it.validity_code !== validity_code
            );
      return cb(isUnique ? true : 'Validity Pack Name must be unique');
    };

  const removeValidityPlan = async (validity_code) => {
    try {
      setValidityEntries((st) => st.filter((k) => k.validity_code !== validity_code));

      setAuthToken();
      await sendRequest.delete(`/city/${city_id}/setting/validity/${validity_code}`);
    } catch (e) {
      console.log(e);
    }
  };

  const resetEditModal = () => {
    setEditValidityModalOpen(false);
    resetSearch();
  };

  const searchValidityPlane = (e) => {
    const inputValue = e.target.value;
    setSearchKeyword(inputValue);

    setValidityEntries((st) => {
      return st.map((k) => {
        if (
          validator.matches(k.validity_name, inputValue, 'i') ||
          validator.matches(k.validity_code, inputValue, 'i')
        ) {
          return { ...k, hideRow: false };
        } else {
          return { ...k, hideRow: true };
        }
      });
    });
  };

  function resetSearch() {
    setSearchKeyword('');
  }

  return (
    <React.Fragment>
      <Modal isOpen={newValidityModalOpen} backdrop='static' size='lg'>
        <ModalHeader
          toggle={() => {
            if (!addingNewValidityEntry) setNewValidityModalOpen(false);
          }}
        >
          Add New Validity Pack
        </ModalHeader>
        <ModalBody>
          <AvForm className='needs-validation' onValidSubmit={addNewValidityPack}>
            <Row>
              <Col md='4'>
                <FormGroup>
                  <Label>Validity Pack Name</Label>
                  <AvField
                    name='validity_name'
                    placeholder='Enter Validity Pack Name'
                    type='text'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Pack name is required',
                      },
                      async: uniqueNameValidatorForValidity('ADD'),
                    }}
                    disabled={addingNewValidityEntry}
                  />
                </FormGroup>
              </Col>

              <Col md='4'>
                <FormGroup>
                  <Label>Extended Validity Period</Label>
                  <AvField
                    name='total_days'
                    placeholder='Enter validity period'
                    type='number'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Total days is required.',
                      },
                      min: {
                        value: 1,
                        errorMessage: 'Total days must be greater than 0.',
                      },
                    }}
                    disabled={addingNewValidityEntry}
                  />
                </FormGroup>
              </Col>

              <Col md='4'>
                <FormGroup>
                  <Label>Total Cost</Label>
                  <AvField
                    name='total_cost'
                    placeholder='Enter total pack cost'
                    type='number'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Cost is required.',
                      },
                      min: {
                        value: 1,
                        errorMessage: 'Total cost must be greater than 0.',
                      },
                    }}
                    disabled={addingNewValidityEntry}
                  />
                </FormGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Button
                type='button'
                color='light'
                onClick={() => {
                  setNewValidityModalOpen(false);
                }}
                disabled={addingNewValidityEntry}
                size='sm'
              >
                Close
              </Button>
              <Button type='submit' color='success' disabled={addingNewValidityEntry} size='sm'>
                {addingNewValidityEntry ? 'Adding...' : 'Add'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={currentSelectedValidity && editValidityModalOpen} backdrop='static' size='lg'>
        <ModalHeader
          toggle={() => {
            if (!editingValidityPlan) resetEditModal();
          }}
        >
          Edit Validity Plan - {currentSelectedValidity?.validity_code}
        </ModalHeader>
        <ModalBody>
          <AvForm className='needs-validation' onValidSubmit={updateValiditySetting}>
            <Row>
              <Col md='4'>
                <FormGroup>
                  <Label>Validity Pack Name</Label>
                  <AvField
                    name='validity_name'
                    placeholder='Enter Validity Pack Name'
                    type='text'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Pack name is required',
                      },
                      async: uniqueNameValidatorForValidity(
                        'UPDATE',
                        currentSelectedValidity?.validity_code
                      ),
                    }}
                    disabled={editingValidityPlan}
                    value={currentSelectedValidity?.validity_name}
                  />
                </FormGroup>
              </Col>

              <Col md='4'>
                <FormGroup>
                  <Label>Extended Validity Period</Label>
                  <AvField
                    name='total_days'
                    placeholder='Enter validity period'
                    type='number'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Total days is required.',
                      },
                      min: {
                        value: 1,
                        errorMessage: 'Total days must be greater than 0.',
                      },
                    }}
                    disabled={editingValidityPlan}
                    value={currentSelectedValidity?.total_days}
                  />
                </FormGroup>
              </Col>

              <Col md='4'>
                <FormGroup>
                  <Label>Total Cost</Label>
                  <AvField
                    name='total_cost'
                    placeholder='Enter total pack cost'
                    type='number'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Cost is required.',
                      },
                      min: {
                        value: 1,
                        errorMessage: 'Total cost must be greater than 0.',
                      },
                    }}
                    disabled={editingValidityPlan}
                    value={currentSelectedValidity?.total_cost}
                  />
                </FormGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Button
                type='button'
                color='light'
                onClick={resetEditModal}
                disabled={editingValidityPlan}
                size='sm'
              >
                Close
              </Button>
              <Button type='submit' color='success' disabled={editingValidityPlan} size='sm'>
                {editingValidityPlan ? 'Loading...' : 'Update'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Setup Validity Settings' breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col md={8}>
                          <FormGroup>
                            <InputGroup>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                  <i className='ri-search-2-line'></i>{' '}
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                type='text'
                                className='form-control'
                                placeholder='Search Validity Plan ...'
                                onChange={searchValidityPlane}
                                value={searchKeyword}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <Row className='justify-content-end'>
                            <Button
                              color='info'
                              className=' waves-effect waves-light mr-2 float-right'
                              onClick={() => {
                                setNewValidityModalOpen(true);
                              }}
                              size='sm'
                            >
                              Add New Validity Pack
                            </Button>
                          </Row>
                        </Col>
                      </Row>

                      {fetchingSetting ? (
                        <div className='text-center'>
                          <Loader type='TailSpin' color='#d3932c' height={80} width={80} />
                        </div>
                      ) : (
                        <div className='table-rep-plugin'>
                          <div className='table-responsive mb-0' data-pattern='priority-columns'>
                            <Table bordered responsive>
                              <thead>
                                <tr>
                                  <th>Validity Code</th>
                                  <th>Validity Name</th>
                                  <th>Total Cost (INR)</th>
                                  <th>Validity Period (Days)</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {validityEntries?.map((item, index) => (
                                  <TableRow
                                    key={index}
                                    validity_item={item}
                                    setCurrentSelectedValidity={setCurrentSelectedValidity}
                                    setEditValidityModalOpen={setEditValidityModalOpen}
                                    hideRow={item.hideRow}
                                    removeValidityPlan={removeValidityPlan}
                                  />
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ValiditySetting;
