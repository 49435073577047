import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Container, FormGroup, Input, Button, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from 'react-loader-spinner';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';

const EditCitySettings = () => {
  const { city_code, city_id } = useParams();
  const [breadcrumbItems] = useState([
    { title: 'City', link: '/' },
    { title: 'City Details', link: '#' },
    { title: city_code, link: `/city/${city_id}/${city_code}` },
    { title: 'Edit Settings', link: '#' },
  ]);
  const history = useHistory();
  const [fetchingSetting, setFetchingSetting] = useState(true);
  const [settingInfo, setSettingInfo] = useState(null);
  const [settingUpdating, setSettingUpdating] = useState(false);
  const [autoAssign, setAutoAssign] = useState(false);

  const updateGeneralSettings = async (event, values) => {
    setSettingUpdating(true);
    try {
      values.auto_assign_mode_status = autoAssign;

      setAuthToken();
      await sendRequest.put(`/city/${city_id}/setting/general`, values);
      history.push(`/city/${city_id}/${city_code}`);
    } catch (e) {
      console.log(e);
      setSettingUpdating(false);
    }
  };

  useEffect(() => {
    setAuthToken();
    sendRequest
      .get(`/city/${city_id}/setting?setting_type=general`)
      .then(({ data: setting_data }) => {
        setSettingInfo(setting_data);
        setAutoAssign(setting_data.auto_assign_mode_status);
        setFetchingSetting(false);
      })
      .catch((err) => {
        history.push(`/city/${city_id}/${city_code}`);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Edit City Settings' breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <AvForm className='needs-validation' onValidSubmit={updateGeneralSettings}>
                    <Row>
                      {fetchingSetting ? (
                        <Col md={12}>
                          <div className='text-center'>
                            <Loader type='TailSpin' color='#d3932c' height={80} width={80} />
                          </div>
                        </Col>
                      ) : (
                        <Col xs={12}>
                          <Row>
                            <Col md={6}>
                              <h5 className='text-capitalize mb-3'>Center Information</h5>
                            </Col>
                            <Col md={6}></Col>
                          </Row>
                          <Row>
                            <>
                              <Col md='4'>
                                <FormGroup>
                                  <Label>Center Name</Label>
                                  <AvField
                                    name='center_name'
                                    placeholder='Enter Center Name'
                                    type='text'
                                    className='form-control'
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Center Name is required.',
                                      },
                                    }}
                                    disabled={settingUpdating}
                                    value={settingInfo?.center_point?.place_name}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md='4'>
                                <FormGroup>
                                  <Label>Center Longitude</Label>
                                  <AvField
                                    name='center_longitude'
                                    placeholder='Please Enter Center Longitude'
                                    type='number'
                                    className='form-control'
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Center Longitude is required.',
                                      },
                                    }}
                                    disabled={settingUpdating}
                                    value={settingInfo?.center_point.coordinates[0]}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md='4'>
                                <FormGroup>
                                  <Label>Center Latitude</Label>
                                  <AvField
                                    name='center_latitude'
                                    placeholder='Please enter Center Latitude'
                                    type='number'
                                    className='form-control'
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Center Latitude is required.',
                                      },
                                    }}
                                    disabled={settingUpdating}
                                    value={settingInfo?.center_point.coordinates[1]}
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          </Row>
                          <hr className='my-3' />
                          <h5 className='text-capitalize mb-3'>Vehicle Points</h5>
                          <Row>
                            <>
                              <Col md='4'>
                                <FormGroup>
                                  <Label>Points For Bike</Label>
                                  <AvField
                                    name='bike_points'
                                    placeholder='Enter Bike Points'
                                    type='number'
                                    className='form-control'
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Bike point is required.',
                                      },
                                      min: {
                                        value: 1,
                                        errorMessage: 'Bike point must be greater than 0.',
                                      },
                                    }}
                                    disabled={settingUpdating}
                                    value={settingInfo?.vehicle_points[0]?.point}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md='4'>
                                <FormGroup>
                                  <Label>Points For Scooter</Label>
                                  <AvField
                                    name='scooter_points'
                                    placeholder='Enter Scooter Points'
                                    type='number'
                                    className='form-control'
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Scooter point is required.',
                                      },
                                      min: {
                                        value: 1,
                                        errorMessage: 'Scooter point must be greater than 0.',
                                      },
                                    }}
                                    disabled={settingUpdating}
                                    value={settingInfo?.vehicle_points[1]?.point}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md='4'>
                                <FormGroup>
                                  <Label>Points For Scooter With Extra Care</Label>
                                  <AvField
                                    name='scooter_care_points'
                                    placeholder='Enter Scooter (Care) Points'
                                    type='number'
                                    className='form-control'
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Scooter (Care) point is required.',
                                      },
                                      min: {
                                        value: 1,
                                        errorMessage:
                                          'Scooter (Care) point must be greater than 0.',
                                      },
                                    }}
                                    disabled={settingUpdating}
                                    value={settingInfo?.vehicle_points[2]?.point}
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          </Row>

                          <hr className='my-3' />
                          <h5 className='text-capitalize mb-3'>Range Selection (Km)</h5>
                          <Row>
                            <>
                              {settingInfo?.ranges?.map((item, index) => (
                                <Col md='2' key={index}>
                                  <FormGroup>
                                    <Label>Range {index + 1}</Label>
                                    <AvField
                                      name={`range_${index + 1}`}
                                      placeholder='Distance (Km)'
                                      type='number'
                                      className='form-control'
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: 'Distance is required',
                                        },
                                        min: {
                                          value: 1,
                                          errorMessage: 'Distance must be greater than 0.',
                                        },
                                      }}
                                      value={item.range_dist}
                                      disabled={settingUpdating}
                                    />
                                  </FormGroup>
                                </Col>
                              ))}
                            </>
                          </Row>

                          <hr className='my-3' />
                          <Row>
                            <Col md='6'>
                              <h5 className='text-capitalize mb-3'>Cost per Point (INR)</h5>
                              <Row>
                                <>
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        name='cost_per_point'
                                        placeholder='Amount in INR'
                                        type='number'
                                        className='form-control'
                                        validate={{
                                          required: {
                                            value: true,
                                            errorMessage: 'Cost per point is required',
                                          },
                                          min: {
                                            value: 1,
                                            errorMessage: 'Amount must be greater than 0.',
                                          },
                                        }}
                                        value={settingInfo?.cost_per_point}
                                        disabled={settingUpdating}
                                      />
                                    </FormGroup>
                                  </Col>
                                </>
                              </Row>
                            </Col>
                            <Col md='6'>
                              <h5 className='text-capitalize mb-3'>Rider Assignment method</h5>
                              <Row>
                                <>
                                  <Col md='6'>
                                    <FormGroup>
                                      <div className='custom-control custom-switch mb-4' dir='ltr'>
                                        <Input
                                          type='checkbox'
                                          className='custom-control-input'
                                          id='customSwitch1'
                                          checked={autoAssign}
                                        />
                                        <Label
                                          className='custom-control-label'
                                          htmlFor='customSwitch1'
                                          onClick={() => {
                                            setAutoAssign(!autoAssign);
                                          }}
                                        >
                                          {autoAssign ? 'Auto Mode on' : 'Auto Mode off'}
                                        </Label>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </>
                              </Row>
                            </Col>
                          </Row>

                          <hr className='my-3' />
                          <Button
                            type='submit'
                            color='success'
                            className=' waves-effect waves-light mr-1 float-right'
                            disabled={settingUpdating}
                          >
                            {settingUpdating ? 'Updating...' : 'Update Setting'}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditCitySettings;
