import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormGroup,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from 'react-loader-spinner';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';
import validator from 'validator';

function TableRow({
  membership_item,
  removeMembershipPlan,
  setCurrentSelectedMembership,
  setEditMembershipModalOpen,
  hideRow,
}) {
  if (hideRow) return null;
  return (
    <tr>
      <th>{membership_item.membership_code}</th>
      <td>{membership_item.membership_name}</td>
      <td>{membership_item.total_point}</td>
      <td>{membership_item.total_cost}</td>
      <td>{membership_item.validity_period}</td>
      <td>
        <Button
          color='primary'
          size='sm'
          className='waves-effect waves-light'
          onClick={() => {
            setCurrentSelectedMembership(membership_item);
            setEditMembershipModalOpen(true);
          }}
        >
          Edit
        </Button>
        <Button
          color='danger'
          size='sm'
          className='waves-effect waves-light ml-3'
          onClick={() => {
            removeMembershipPlan(membership_item.membership_code);
          }}
        >
          Remove
        </Button>
      </td>
    </tr>
  );
}

const MembershipSettings = () => {
  const { city_code, city_id } = useParams();
  const [breadcrumbItems] = useState([
    { title: 'City', link: '/' },
    { title: 'City Details', link: '#' },
    { title: city_code, link: `/city/${city_id}/${city_code}` },
    { title: 'Membership Settings', link: '#' },
  ]);
  const history = useHistory();

  const [fetchingSetting, setFetchingSetting] = useState(true);
  const [membershipEntries, setMembershipEntries] = useState([]);
  const [addingNewMembershipEntry, setAddingNewMembershipEntry] = useState(false);
  const [newMembershipModalOpen, setNewMembershipModalOpen] = useState(false);
  const [currentSelectedMembership, setCurrentSelectedMembership] = useState(null);
  const [editMembershipModalOpen, setEditMembershipModalOpen] = useState(false);
  const [editingMembershipPlan, setEditingMembershipPlan] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');

  const fetchMembershipSetting = async () => {
    setFetchingSetting(true);
    try {
      setAuthToken();
      const { data: membership_data } = await sendRequest.get(
        `/city/${city_id}/setting?setting_type=membership`
      );
      setMembershipEntries(membership_data);
      setFetchingSetting(false);
    } catch (e) {
      history.push(`/city/${city_id}/${city_code}`);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchMembershipSetting();
    // eslint-disable-next-line
  }, []);

  const addNewMembershipPlan = async (event, values) => {
    try {
      setAddingNewMembershipEntry(true);

      setAuthToken();
      const { data: response } = await sendRequest.post(`/city/${city_id}/setting/membership`, {
        membership_name: values.membership_name,
        total_point: Number(values.total_point),
        total_cost: Number(values.total_cost),
        validity_period: Number(values.validity_period),
      });

      setMembershipEntries((st) => [...st, response.data]);
      setAddingNewMembershipEntry(false);
      setNewMembershipModalOpen(false);
      resetSearch();
    } catch (e) {
      console.log(e);
    }
  };

  const removeMembershipPlan = async (membership_code) => {
    try {
      setMembershipEntries((st) => st.filter((k) => k.membership_code !== membership_code));

      setAuthToken();
      await sendRequest.delete(`/city/${city_id}/setting/membership/${membership_code}`);
    } catch (e) {
      console.log(e);
    }
  };

  const updateMembershipPlan = async (event, values) => {
    setEditingMembershipPlan(true);
    try {
      setAuthToken();
      const { data: response } = await sendRequest.put(
        `/city/${city_id}/setting/membership/${currentSelectedMembership.membership_code}`,
        {
          membership_name: values.membership_name,
          total_point: Number(values.total_point),
          total_cost: Number(values.total_cost),
          validity_period: Number(values.validity_period),
        }
      );
      setMembershipEntries((st) =>
        st.map((k) => {
          if (k.membership_code === response.data.membership_code) {
            return response.data;
          } else return k;
        })
      );
      resetEditModal();
    } catch (e) {
      console.log(e);
    }
    setEditingMembershipPlan(false);
  };

  const uniqueNameValidator =
    (operation_type, membership_code) => async (value, ctx, input, cb) => {
      let trimmedValue = value?.trim().toLowerCase() || '';
      let isUnique =
        operation_type === 'ADD'
          ? membershipEntries.every(
              (it) => it.membership_name.toLowerCase().trim() !== trimmedValue
            )
          : !membershipEntries.some(
              (it) =>
                it.membership_name.toLowerCase().trim() === trimmedValue &&
                it.membership_code !== membership_code
            );
      return cb(isUnique ? true : 'Membership Name must be unique');
    };

  const resetEditModal = () => {
    setEditMembershipModalOpen(false);
    resetSearch();
  };

  const searchMembership = (e) => {
    const inputValue = e.target.value;
    setSearchKeyword(inputValue);

    setMembershipEntries((st) => {
      return st.map((k) => {
        if (
          validator.matches(k.membership_name, inputValue, 'i') ||
          validator.matches(k.membership_code, inputValue, 'i')
        ) {
          return { ...k, hideRow: false };
        } else {
          return { ...k, hideRow: true };
        }
      });
    });
  };

  function resetSearch() {
    setSearchKeyword('');
  }

  return (
    <React.Fragment>
      <Modal isOpen={newMembershipModalOpen} backdrop='static' size='lg'>
        <ModalHeader
          toggle={() => {
            if (!addingNewMembershipEntry) setNewMembershipModalOpen(false);
          }}
        >
          Add New Membership
        </ModalHeader>
        <ModalBody>
          <AvForm className='needs-validation' onValidSubmit={addNewMembershipPlan}>
            <Row>
              <Col md='6'>
                <FormGroup>
                  <Label>Membership Name</Label>
                  <AvField
                    name='membership_name'
                    placeholder='Enter Membership Name'
                    type='text'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Membership name is required',
                      },
                      async: uniqueNameValidator('ADD'),
                    }}
                    disabled={addingNewMembershipEntry}
                  />
                </FormGroup>
              </Col>

              <Col md='6'>
                <FormGroup>
                  <Label>Total Fixed Points</Label>
                  <AvField
                    name='total_point'
                    placeholder='Enter total delivery point'
                    type='number'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Total point is required.',
                      },
                      min: {
                        value: 1,
                        errorMessage: 'Total point must be greater than 0.',
                      },
                    }}
                    disabled={addingNewMembershipEntry}
                  />
                </FormGroup>
              </Col>

              <Col md='6'>
                <FormGroup>
                  <Label>Total Cost</Label>
                  <AvField
                    name='total_cost'
                    placeholder='Enter total membership cost'
                    type='number'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Cost is required.',
                      },
                      min: {
                        value: 1,
                        errorMessage: 'Total cost must be greater than 0.',
                      },
                    }}
                    disabled={addingNewMembershipEntry}
                  />
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup>
                  <Label>Validity Period</Label>
                  <AvField
                    name='validity_period'
                    placeholder='Enter Validity Period (Days)'
                    type='number'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'This field is required.',
                      },
                      min: {
                        value: 1,
                        errorMessage: 'Validity period must be greater than 0.',
                      },
                    }}
                    disabled={addingNewMembershipEntry}
                  />
                </FormGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Button
                type='button'
                color='light'
                onClick={() => {
                  setNewMembershipModalOpen(false);
                }}
                disabled={addingNewMembershipEntry}
                size='sm'
              >
                Close
              </Button>
              <Button type='submit' color='success' disabled={addingNewMembershipEntry} size='sm'>
                {addingNewMembershipEntry ? 'Adding...' : 'Add'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={currentSelectedMembership && editMembershipModalOpen}
        backdrop='static'
        size='lg'
      >
        <ModalHeader
          toggle={() => {
            if (!editingMembershipPlan) resetEditModal();
          }}
        >
          Edit Membership Plan - {currentSelectedMembership?.membership_code}
        </ModalHeader>
        <ModalBody>
          <AvForm className='needs-validation' onValidSubmit={updateMembershipPlan}>
            <Row>
              <Col md='6'>
                <FormGroup>
                  <Label>Membership Name</Label>
                  <AvField
                    name='membership_name'
                    placeholder='Enter Membership Name'
                    type='text'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Membership name is required',
                      },
                      async: uniqueNameValidator(
                        'UPDATE',
                        currentSelectedMembership?.membership_code
                      ),
                    }}
                    disabled={editingMembershipPlan}
                    value={currentSelectedMembership?.membership_name}
                  />
                </FormGroup>
              </Col>

              <Col md='6'>
                <FormGroup>
                  <Label>Total Fixed Points</Label>
                  <AvField
                    name='total_point'
                    placeholder='Enter total delivery point'
                    type='number'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Total point is required.',
                      },
                      min: {
                        value: 1,
                        errorMessage: 'Total point must be greater than 0.',
                      },
                    }}
                    disabled={editingMembershipPlan}
                    value={currentSelectedMembership?.total_point}
                  />
                </FormGroup>
              </Col>

              <Col md='6'>
                <FormGroup>
                  <Label>Total Cost</Label>
                  <AvField
                    name='total_cost'
                    placeholder='Enter total membership cost'
                    type='number'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Cost is required.',
                      },
                      min: {
                        value: 1,
                        errorMessage: 'Total cost must be greater than 0.',
                      },
                    }}
                    disabled={editingMembershipPlan}
                    value={currentSelectedMembership?.total_cost}
                  />
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup>
                  <Label>Validity Period</Label>
                  <AvField
                    name='validity_period'
                    placeholder='Enter Validity Period (Days)'
                    type='number'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'This field is required.',
                      },
                      min: {
                        value: 1,
                        errorMessage: 'Validity period must be greater than 0.',
                      },
                    }}
                    disabled={editingMembershipPlan}
                    value={currentSelectedMembership?.validity_period}
                  />
                </FormGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Button
                type='button'
                color='light'
                onClick={resetEditModal}
                disabled={editingMembershipPlan}
                size='sm'
              >
                Close
              </Button>
              <Button type='submit' color='success' disabled={editingMembershipPlan} size='sm'>
                {editingMembershipPlan ? 'Loading...' : 'Update'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Setup Membership Settings' breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col md={8}>
                          <FormGroup>
                            <InputGroup>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                  <i className='ri-search-2-line'></i>{' '}
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                type='text'
                                className='form-control'
                                placeholder='Search Membership ...'
                                onChange={searchMembership}
                                value={searchKeyword}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <Row className='justify-content-end'>
                            <Button
                              color='info'
                              className=' waves-effect waves-light mr-2 float-right'
                              onClick={() => {
                                setNewMembershipModalOpen(true);
                              }}
                              size='sm'
                            >
                              Add New Membership
                            </Button>
                          </Row>
                        </Col>
                      </Row>
                      {fetchingSetting ? (
                        <div className='text-center'>
                          <Loader type='TailSpin' color='#d3932c' height={80} width={80} />
                        </div>
                      ) : (
                        <div className='table-rep-plugin'>
                          <div className='table-responsive mb-0' data-pattern='priority-columns'>
                            <Table bordered responsive>
                              <thead>
                                <tr>
                                  <th>Membership Code</th>
                                  <th>Membership Name</th>
                                  <th>Total Delivery Points (Fixed)</th>
                                  <th>Total Cost (INR)</th>
                                  <th>Validity Period (Days)</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {membershipEntries?.map((item, index) => (
                                  <TableRow
                                    membership_item={item}
                                    key={index}
                                    removeMembershipPlan={removeMembershipPlan}
                                    setCurrentSelectedMembership={setCurrentSelectedMembership}
                                    setEditMembershipModalOpen={setEditMembershipModalOpen}
                                    hideRow={item.hideRow}
                                  />
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MembershipSettings;
