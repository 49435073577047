import React, { useState, useRef } from 'react';
import { Row, Col, Card, CardBody, Container, FormGroup, Button, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useParams } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';

const NotificationControl = () => {
  const { city_code, city_id } = useParams();
  const [breadcrumbItems] = useState([
    { title: 'City', link: '/' },
    { title: 'City Details', link: '#' },
    { title: city_code, link: `/city/${city_id}/${city_code}` },
    { title: 'Notification', link: '#' },
  ]);
  const [notification_sending, set_notification_sending] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);

  const shop_notification_form = useRef();
  const rider_notification_form = useRef();

  const handleSendNotification = (app_type) => async (event, values) => {
    set_notification_sending(true);
    try {
      setAuthToken();
      const result = await sendRequest.post(`/city/${city_id}/${app_type}/notification`, {
        notification_body: values.notification_body,
        user_notification_title: values.notification_title,
      });

      setOpenResponseModal(true);
      setIsSucceed(true);
      setResponseMessage(result.data.message);

      if (app_type === 'shop_app') {
        shop_notification_form && shop_notification_form.current.reset();
      }
      if (app_type === 'rider_app') {
        rider_notification_form && rider_notification_form.current.reset();
      }
    } catch (e) {
      setOpenResponseModal(true);
      setIsSucceed(false);
      setResponseMessage(e?.response?.data?.error);
    }
    set_notification_sending(false);
  };

  return (
    <React.Fragment>
      {openResponseModal && (
        <SweetAlert
          success={isSucceed}
          error={!isSucceed}
          title={isSucceed ? 'Success' : 'Error'}
          onConfirm={() => {
            setOpenResponseModal(false);
          }}
        >
          {responseMessage}
        </SweetAlert>
      )}
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Notification Control' breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col md='6'>
                          <h5 className='text-capitalize mb-3'>KD Shops</h5>

                          <Row>
                            <Col md='12'>
                              <AvForm
                                className='needs-validation'
                                onValidSubmit={handleSendNotification('shop_app')}
                                ref={shop_notification_form}
                              >
                                <FormGroup>
                                  <Label>Notification Title</Label>
                                  <AvField
                                    name='notification_title'
                                    type='text'
                                    className='form-control'
                                    disabled={notification_sending}
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Title is required',
                                      },
                                    }}
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label>Notification Body</Label>
                                  <AvField
                                    name='notification_body'
                                    type='textarea'
                                    className='form-control'
                                    disabled={notification_sending}
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Description is required',
                                      },
                                    }}
                                  />
                                </FormGroup>
                                <Button
                                  color='success'
                                  className='waves-effect waves-light mr-3'
                                  size='sm'
                                  type='submit'
                                  disabled={notification_sending}
                                >
                                  Send Notification
                                </Button>
                              </AvForm>
                            </Col>
                          </Row>
                        </Col>
                        <Col md='6'>
                          <h5 className='text-capitalize mb-3'>KD Riders</h5>
                          <Row>
                            <Col md='12'>
                              <AvForm
                                className='needs-validation'
                                onValidSubmit={handleSendNotification('rider_app')}
                                ref={rider_notification_form}
                              >
                                <FormGroup>
                                  <Label>Notification Title</Label>
                                  <AvField
                                    name='notification_title'
                                    type='text'
                                    className='form-control'
                                    disabled={notification_sending}
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Title is required',
                                      },
                                    }}
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label>Notification Body</Label>
                                  <AvField
                                    name='notification_body'
                                    type='textarea'
                                    className='form-control'
                                    disabled={notification_sending}
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Description is required',
                                      },
                                    }}
                                  />
                                </FormGroup>
                                <Button
                                  color='success'
                                  className='waves-effect waves-light mr-3'
                                  size='sm'
                                  type='submit'
                                  disabled={notification_sending}
                                >
                                  Send Notification
                                </Button>
                              </AvForm>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NotificationControl;
