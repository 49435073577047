import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Container, Table, Input, Button } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';
import moment from 'moment';

const MobileApp = () => {
  const [breadcrumbItems] = useState([
    { title: 'KD Notification', link: '#' },
    { title: 'Details', link: '#' },
  ]);
  const [notificationStatLoading, setNotificationStatLoading] = useState(true);
  const [notificationStat, setNotificationStat] = useState({});
  const [dateTimeValue, setDateTimeValue] = useState({
    shop_app: moment(new Date(new Date().setDate(new Date().getDate() - 15))).format(
      'YYYY-MM-DD[T]hh:mm'
    ),
    rider_app: moment(new Date(new Date().setDate(new Date().getDate() - 15))).format(
      'YYYY-MM-DD[T]hh:mm'
    ),
    admin_web: moment(new Date(new Date().setDate(new Date().getDate() - 15))).format(
      'YYYY-MM-DD[T]hh:mm'
    ),
  });
  const [removingNotification, setRemovingNotification] = useState({
    admin_web: false,
    shop_app: false,
    rider_app: false,
  });

  useEffect(() => {
    fetchNotificationStat();
    // eslint-disable-next-line
  }, []);

  async function fetchNotificationStat() {
    setNotificationStatLoading(true);
    try {
      setAuthToken();
      const { data } = await sendRequest.get('/notification/stat');
      setNotificationStat(data);
    } catch (e) {
      console.log(e);
    }
    setNotificationStatLoading(false);
  }

  function dateTimeChangeHandler(e) {
    setDateTimeValue({ ...dateTimeValue, [e.target.name]: e.target.value });
  }

  const removeNotificationRequest = (client_type, date_time_value) => async () => {
    setRemovingNotification({ ...removingNotification, [client_type]: true });
    try {
      setAuthToken();
      await sendRequest.put(`/notification/remove/${client_type}`, {
        date_time: new Date(date_time_value),
      });
      fetchNotificationStat();
    } catch (e) {
      console.log(e);
    }
    setRemovingNotification({ ...removingNotification, [client_type]: false });
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Notification Setting' breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div className='table-rep-plugin'>
                    <div className='table-responsive mb-0' data-pattern='priority-columns'>
                      <Table striped bordered responsive>
                        <thead>
                          <tr>
                            <th>Application</th>
                            <th>Total Notifications</th>
                            <th>Date & Time</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Admin Web Client</td>
                            <td>
                              {notificationStatLoading
                                ? 'Loading ...'
                                : notificationStat?.admin_notification_count}
                            </td>
                            <td>
                              <Input
                                name='admin_web'
                                className='form-control'
                                type='datetime-local'
                                value={dateTimeValue.admin_web}
                                onChange={dateTimeChangeHandler}
                              />
                            </td>
                            <td className='d-flex justify-content-center'>
                              <Button
                                color='danger'
                                size='sm'
                                className='waves-effect waves-light mr-3'
                                onClick={removeNotificationRequest(
                                  'admin_web',
                                  dateTimeValue.admin_web
                                )}
                                disabled={removingNotification.admin_web}
                              >
                                <i className='ri-delete-bin-line align-middle mr-1'></i>{' '}
                                {removingNotification.admin_web
                                  ? 'Removing Notification'
                                  : 'Remove Notification'}
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td>KD Shop Mobile App</td>
                            <td>
                              {notificationStatLoading
                                ? 'Loading ...'
                                : notificationStat?.shop_notification_count}
                            </td>
                            <td>
                              <Input
                                name='shop_app'
                                className='form-control'
                                type='datetime-local'
                                value={dateTimeValue.shop_app}
                                onChange={dateTimeChangeHandler}
                              />
                            </td>
                            <td className='d-flex justify-content-center'>
                              <Button
                                color='danger'
                                size='sm'
                                className='waves-effect waves-light mr-3'
                                onClick={removeNotificationRequest(
                                  'shop_app',
                                  dateTimeValue.shop_app
                                )}
                                disabled={removingNotification.shop_app}
                              >
                                <i className='ri-delete-bin-line align-middle mr-1'></i>{' '}
                                {removingNotification.shop_app
                                  ? 'Removing Notification'
                                  : 'Remove Notification'}
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td>KD Rider Mobile App</td>
                            <td>
                              {notificationStatLoading
                                ? 'Loading ...'
                                : notificationStat?.rider_notification_count}
                            </td>
                            <td>
                              <Input
                                name='rider_app'
                                className='form-control'
                                type='datetime-local'
                                value={dateTimeValue.rider_app}
                                onChange={dateTimeChangeHandler}
                              />
                            </td>
                            <td className='d-flex justify-content-center'>
                              <Button
                                color='danger'
                                size='sm'
                                className='waves-effect waves-light mr-3'
                                onClick={removeNotificationRequest(
                                  'rider_app',
                                  dateTimeValue.rider_app
                                )}
                                disabled={removingNotification.rider_app}
                              >
                                <i className='ri-delete-bin-line align-middle mr-1'></i>{' '}
                                {removingNotification.rider_app
                                  ? 'Removing Notification'
                                  : 'Remove Notification'}
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MobileApp;
