import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ProfileIconImg from '../../assets/images/profile-img.jpg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className='d-inline-block user-dropdown'
        >
          <DropdownToggle
            tag='button'
            className='btn header-item waves-effect'
            id='page-header-user-dropdown'
          >
            <img
              className='rounded-circle header-profile-user mr-1'
              src={ProfileIconImg}
              alt='Header Avatar'
            />
            <span className='d-none d-xl-inline-block ml-1 text-transform'>
              {this.props.myLoginState?.user?.name}
            </span>
            <i className='mdi mdi-chevron-down d-none ml-1 d-xl-inline-block'></i>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem className='text-danger' href='/logout'>
              <i className='ri-shut-down-line align-middle mr-1 text-danger'></i> Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { layoutType } = state.Layout;
  const myLoginState = state.Login;
  return { layoutType, myLoginState };
};

export default connect(mapStateToProps)(withRouter(ProfileMenu));
