import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Row,
  Col,
  CardBody,
  Container,
  Badge,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Table,
} from 'reactstrap';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import stateList from '../../helpers/state_list';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';
import moment from 'moment';
import { AvForm, AvField } from 'availity-reactstrap-validation';

const Dashboard = () => {
  const [breadcrumbItems] = useState([
    { title: 'KD', link: '#' },
    { title: 'Home', link: '#' },
  ]);
  const history = useHistory();
  const [dataLoading, setDataLoading] = useState(true);
  const [cityData, setCityData] = useState([]);
  const [newCityModalOpen, setNewCityModelOpen] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [addingNewCity, setAddingNewCity] = useState(false);
  const stateSelectionOptions = Object.keys(stateList).map((it) => {
    return {
      value: stateList[it],
      label: `${stateList[it]} (${it})`,
    };
  });

  useEffect(() => {
    fetchCityList();
    // eslint-disable-next-line
  }, []);

  async function fetchCityList() {
    setDataLoading(true);
    try {
      setAuthToken();
      const { data: city_data } = await sendRequest.get('/city');
      setCityData(city_data);
    } catch (e) {
      console.log(e);
    }
    setDataLoading(false);
  }

  const handleStateSelection = (item) => {
    setSelectedState(item);
  };

  const addNewCity = async (event, values) => {
    setAddingNewCity(true);
    try {
      setAuthToken();

      const { data } = await sendRequest.post('/city', {
        city_name: values.city_name,
        state_name: selectedState.value,
      });

      setCityData([...cityData, data]);
      setSelectedState(null);
      setNewCityModelOpen(false);
    } catch (e) {
      console.log(e);
    }
    setAddingNewCity(false);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={newCityModalOpen}
        toggle={() => {
          setNewCityModelOpen(false);
        }}
      >
        <ModalHeader toggle={() => setNewCityModelOpen(false)}>Add New City</ModalHeader>
        <ModalBody>
          <AvForm className='needs-validation' onValidSubmit={addNewCity}>
            <Row>
              <Col md='12'>
                <FormGroup>
                  <Label>City Name</Label>
                  <AvField
                    name='city_name'
                    placeholder='Enter City Name'
                    type='text'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'City name is required',
                      },
                    }}
                    disabled={addingNewCity}
                  />
                </FormGroup>
              </Col>
              <Col md='12'>
                <FormGroup>
                  <Label>State</Label>
                  <Select
                    options={stateSelectionOptions}
                    disabled={addingNewCity}
                    onChange={handleStateSelection}
                    value={selectedState}
                    isClearable={true}
                  />
                </FormGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Button
                type='button'
                color='light'
                onClick={() => {
                  setNewCityModelOpen(false);
                }}
                disabled={addingNewCity}
              >
                Close
              </Button>

              <Button type='submit' color='success' disabled={addingNewCity || !selectedState}>
                {addingNewCity ? 'Loading ...' : 'Add City'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>

      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Home' breadcrumbItems={breadcrumbItems} />
        </Container>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                {dataLoading ? (
                  <div className='text-center'>
                    <Loader type='TailSpin' color='#d3932c' height={80} width={80} />
                  </div>
                ) : (
                  <>
                    <div className='mb-3 d-flex justify-content-end'>
                      <Button
                        color='primary'
                        size='sm'
                        className='waves-effect waves-light'
                        onClick={() => {
                          setNewCityModelOpen(true);
                        }}
                      >
                        Add New City
                      </Button>
                    </div>

                    <div className='table-rep-plugin'>
                      <div className='table-responsive mb-0' data-pattern='priority-columns'>
                        <Table striped bordered responsive>
                          <thead>
                            <tr>
                              <th>City Code</th>
                              <th>City Name</th>
                              <th>State</th>
                              <th>Active Status</th>
                              <th>Registered At</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cityData.map((item) => {
                              return (
                                <tr key={item._id} style={{ cursor: 'pointer' }}>
                                  <td>C{item.city_seq_code}</td>
                                  <td>{item.city_name}</td>
                                  <td>{item.state_name}</td>
                                  <td>
                                    <Badge
                                      pill
                                      className={`badge-soft-${
                                        item.is_active ? 'success' : 'danger'
                                      } font-size-14`}
                                    >
                                      {`${item.is_active ? 'Active' : 'Not Active'}`}
                                    </Badge>
                                  </td>
                                  <td>{moment(item.createdAt).format('DD-MM-YYYY')}</td>
                                  <td>
                                    <Button
                                      color='info'
                                      size='sm'
                                      className='waves-effect waves-light'
                                      onClick={() => {
                                        history.push(`/city/${item._id}/C${item.city_seq_code}`);
                                      }}
                                    >
                                      View Details
                                    </Button>
                                    <Button
                                      color='success'
                                      size='sm'
                                      className='waves-effect waves-light ml-3'
                                      onClick={() => {
                                        history.push(
                                          `/city/${item._id}/C${item.city_seq_code}/notification`
                                        );
                                      }}
                                    >
                                      Send Notification
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
