import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import _ from 'lodash';
import { saveFileToLocal } from '../../helpers/csv-file';
import { useParams } from 'react-router-dom';

const animatedComponents = makeAnimated();

function DateFilterText({ start_date, end_date }) {
  const start_date_str = moment(start_date).format('DD MMM YYYY');
  const end_date_str = moment(end_date).format('DD MMM YYYY');

  if (start_date_str === end_date_str) {
    return <React.Fragment>{start_date_str}</React.Fragment>;
  } else {
    return (
      <React.Fragment>
        {start_date_str} - {end_date_str}
      </React.Fragment>
    );
  }
}

function calculateDurationInHours(durationInSeconds) {
  return parseFloat(Number(durationInSeconds / (60 * 60)).toFixed(1));
}

function costPerOrderCalculate(total_points, total_amount) {
  return total_points !== 0
    ? '₹ ' + parseFloat(total_amount / total_points).toFixed(2)
    : 'NA';
}

function pointAnalyticsCalculation(numerator, denominator) {
  return denominator !== 0
    ? parseFloat(numerator / denominator).toFixed(2)
    : 'NA';
}

const RiderSalaryStatistics = () => {
  const { city_code, city_id } = useParams();

  const [breadcrumbItems] = useState([
    { title: 'City', link: '/' },
    { title: 'City Details', link: '#' },
    { title: city_code, link: `/city/${city_id}/${city_code}` },
    { title: 'Statistics', link: '#' },
    { title: 'Rider Salary', link: '#' }
  ]);
  const [dataLoading, setDataLoading] = useState(true);
  const [salaryStatData, setSalaryStatData] = useState(null);
  const [dateFilterModalOpen, setDateFilterModalOpen] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    start_date: moment(
      new Date(new Date().setDate(new Date().getDate() - 15))
    ).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD')
  });
  const [riderOptions, setRiderOptions] = useState([]);
  const [riderListFetching, setRiderListFetching] = useState(false);
  const [selectedRiderItems, setSelectedRiderItems] = useState([]);
  const [sortingKey, setSortingKey] = useState('date_filter$desc');
  const [exportingCsv, setExportingCsv] = useState(false);
  const [riderIdsForCsv, setRiderIdsForCsv] = useState('');

  useEffect(() => {
    fetchData();
    fetchRiderOptionsList();
    // eslint-disable-next-line
  }, []);

  const fetchData = async (rider_ids, sorting_key) => {
    try {
      setDataLoading(true);
      setAuthToken();
      const { data: salary_stat_data } = await sendRequest.get(
        `/city/${city_id}/statistics/rider_salary?start_date=${
          dateFilter.start_date
        }&end_date=${dateFilter.end_date}${
          rider_ids ? '&rider_ids=' + rider_ids : ''
        }${sorting_key ? '&sorting=' + sorting_key : '&sorting=' + sortingKey}`
      );
      setSalaryStatData(salary_stat_data);
      setDataLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  function handleRiderOptionSelect(itemArr) {
    setSelectedRiderItems(itemArr);
  }

  async function fetchRiderOptionsList() {
    setRiderListFetching(true);
    try {
      setAuthToken();
      const { data } = await sendRequest.get(
        `/city/${city_id}/statistics/rider_list?rider_type=stat_list&page=1&limit=100`
      );
      setRiderOptions(
        data.results.map((k) => {
          return {
            value: k,
            label: `${k.name} - ${k.rider_code} - ${k.phone_number}`
          };
        })
      );
    } catch (e) {
      console.log(e);
    }
    setRiderListFetching(false);
  }

  function applyRiderFilter() {
    if (Array.isArray(selectedRiderItems)) {
      const rider_ids = selectedRiderItems
        .map((k) => {
          return k.value._id;
        })
        .join(',');
      setRiderIdsForCsv(rider_ids);
      fetchData(rider_ids);
    } else {
      fetchData();
    }
  }

  async function downloadCsvData() {
    setExportingCsv(true);
    try {
      setAuthToken();
      const { data: csvFile } = await sendRequest.get(
        `/city/${city_id}/statistics/rider_salary/csv?start_date=${
          dateFilter.start_date
        }&end_date=${dateFilter.end_date}${
          riderIdsForCsv ? '&rider_ids=' + riderIdsForCsv : ''
        }${sortingKey ? '&sorting=' + sortingKey : ''}`,
        {
          responseType: 'blob'
        }
      );

      await saveFileToLocal(csvFile, 'rider_salary_statistics');
    } catch (e) {
      console.log(e);
    }
    setExportingCsv(false);
  }

  return (
    <React.Fragment>
      <Modal isOpen={dateFilterModalOpen} backdrop="static" size="lg">
        <ModalHeader
          toggle={() => {
            setDateFilterModalOpen(false);
          }}
        >
          Select Date Range
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="6">
              <FormGroup row>
                <Label
                  htmlFor="example-date-input"
                  className="col-md-3 col-form-label"
                >
                  Start Date
                </Label>
                <Col md={6}>
                  <Input
                    className="form-control"
                    type="date"
                    defaultValue={dateFilter.start_date}
                    id="example-date-input"
                    onChange={(e) => {
                      setDateFilter({
                        ...dateFilter,
                        start_date: e.target.value
                      });
                    }}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup row>
                <Label
                  htmlFor="example-date-input"
                  className="col-md-3 col-form-label"
                >
                  End Date
                </Label>
                <Col md={6}>
                  <Input
                    className="form-control"
                    type="date"
                    defaultValue={dateFilter.end_date}
                    id="example-date-input"
                    onChange={(e) => {
                      setDateFilter({
                        ...dateFilter,
                        end_date: e.target.value
                      });
                    }}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>

          <ModalFooter>
            <Button
              type="button"
              color="light"
              onClick={() => {
                setDateFilterModalOpen(false);
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              color="success"
              disabled={
                dateFilter.start_date === null || dateFilter.end_date === null
              }
              onClick={() => {
                setDateFilterModalOpen(false);
                fetchData();
              }}
            >
              Add Filter
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="KD Riders Salary Statistics"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <>
                    <Row className="d-flex align-items-center justify-content-end">
                      <Col md={4}>
                        <FormGroup>
                          <Row>
                            <Col md={8}>
                              <Label>Select Rider</Label>
                              <Select
                                components={animatedComponents}
                                isMulti
                                options={riderOptions}
                                value={selectedRiderItems}
                                onChange={handleRiderOptionSelect}
                                disabled={riderListFetching}
                              />
                            </Col>
                            <Col className="d-flex justify-content-start align-items-end">
                              <Button
                                className="btn btn-info mr-3"
                                onClick={applyRiderFilter}
                                disabled={dataLoading}
                              >
                                Apply
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <Label className="col-form-label mb-0">Sorting</Label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              fetchData(null, e.target.value);
                              setSortingKey(e.target.value);
                            }}
                            value={sortingKey}
                            disabled={dataLoading}
                          >
                            <option value={'date_filter$desc'}>
                              Date (Newest First)
                            </option>
                            <option value={'date_filter$asc'}>
                              Date (Oldest First)
                            </option>
                            <option value={'base_salary$desc'}>
                              Base Salary (High to Low)
                            </option>
                            <option value={'base_salary$asc'}>
                              Base Salary (Low to High)
                            </option>
                            <option value={'fuel_amount$desc'}>
                              Fuel Amount (High to Low)
                            </option>
                            <option value={'fuel_amount$asc'}>
                              Fuel Amount (Low to High)
                            </option>
                            <option value={'incentive_amount$desc'}>
                              Incentive (High to Low)
                            </option>
                            <option value={'incentive_amount$asc'}>
                              Incentive (Low to High)
                            </option>
                            <option value={'total_salary_amount$desc'}>
                              Total Amount (High to Low)
                            </option>
                            <option value={'total_salary_amount$asc'}>
                              Total Amount (Low to High)
                            </option>
                            <option value={'other_amount$desc'}>
                              Extra Charges (High to Low)
                            </option>
                            <option value={'other_amount$asc'}>
                              Extra Charges (Low to High)
                            </option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <p className="text-primary mr-3 mb-0">
                          Date Filter Applied |{' '}
                          <DateFilterText
                            start_date={dateFilter.start_date}
                            end_date={dateFilter.end_date}
                          />
                        </p>
                        <Button
                          className="btn btn-info mr-3"
                          onClick={() => setDateFilterModalOpen(true)}
                          disabled={dataLoading}
                        >
                          <i className=" ri-filter-line align-middle mr-1 "></i>
                          Filter Date
                        </Button>
                        <Button
                          color="primary"
                          className="float-right"
                          disabled={exportingCsv || dataLoading}
                          onClick={downloadCsvData}
                        >
                          <i className="ri-download-cloud-2-line align-middle mr-2"></i>
                          {exportingCsv ? 'Processing ...' : 'Export as CSV'}
                        </Button>
                      </Col>
                    </Row>
                    {dataLoading ? (
                      <div className="text-center">
                        <Loader
                          type="TailSpin"
                          color="#d3932c"
                          height={80}
                          width={80}
                        />
                      </div>
                    ) : (
                      <div className="table-rep-plugin mt-3">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <ReactTooltip
                            place="top"
                            type="dark"
                            effect="solid"
                            multiline={true}
                          />
                          <Table bordered responsive>
                            <thead>
                              <tr>
                                <th>SL No</th>
                                <th>Rider Name</th>
                                <th>Date</th>
                                <th>On Duty</th>
                                <th>Off Duty</th>
                                <th>WH</th>
                                <th>Pt. & Order</th>
                                <th>Salary</th>
                                <th>Incentive</th>
                                <th>Fuel</th>
                                <th>Other</th>
                                <th>Total Amount</th>
                                <th
                                  style={{
                                    fontWeight: 'bolder',
                                    color: 'black'
                                  }}
                                >
                                  Cost/(Pt. & Order)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {salaryStatData?.results?.map((item, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td
                                    style={{
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      maxWidth: '125px',
                                      cursor: 'pointer'
                                    }}
                                    data-tip={item?.rider_details?.name}
                                  >
                                    {item?.rider_details?.name}
                                  </td>
                                  <td>
                                    {moment(
                                      new Date(item.start_timestamp)
                                    ).format('DD-MM-YYYY')}
                                  </td>
                                  <td>
                                    {moment(
                                      new Date(item.start_timestamp)
                                    ).format('hh:mm A')}
                                  </td>
                                  <td>
                                    {moment(
                                      new Date(item.end_timestamp)
                                    ).format('hh:mm A')}
                                  </td>
                                  <td>
                                    {calculateDurationInHours(
                                      item.total_duration
                                    )}{' '}
                                  </td>
                                  <td>
                                    {item.total_points} | {item.order_count}
                                  </td>
                                  <td>₹ {item.total_base_amount}</td>
                                  <td>
                                    ₹ {item.slab_details?.incentive_amount}
                                  </td>
                                  <td>₹ {item.total_fuel_amount}</td>
                                  <td>₹ {item.extra_amount}</td>
                                  <td>
                                    ₹ {item.total_salary_amount_without_others}
                                  </td>
                                  <td
                                    style={{
                                      fontWeight: 'bolder',
                                      color: 'black'
                                    }}
                                  >
                                    {costPerOrderCalculate(
                                      item.total_points,
                                      item.total_salary_amount_without_others
                                    )}
                                    {' | '}
                                    {costPerOrderCalculate(
                                      item.order_count,
                                      item.total_salary_amount_without_others
                                    )}
                                  </td>
                                </tr>
                              ))}
                              <tr
                                className="bg-light footer-row-rider-stat"
                                style={{ fontWeight: 'bolder', color: 'black' }}
                              >
                                <td colSpan={5}>
                                  {_.upperCase('total calculation')}
                                </td>
                                <td>
                                  {salaryStatData?.total_counting_row?.duration}
                                </td>
                                <td>
                                  {salaryStatData?.total_counting_row?.points} |{' '}
                                  {
                                    salaryStatData?.total_counting_row
                                      ?.order_count
                                  }
                                </td>
                                <td>
                                  ₹{' '}
                                  {
                                    salaryStatData?.total_counting_row
                                      ?.base_salary
                                  }
                                </td>
                                <td>
                                  ₹{' '}
                                  {
                                    salaryStatData?.total_counting_row
                                      ?.incentive
                                  }
                                </td>
                                <td>
                                  ₹{' '}
                                  {
                                    salaryStatData?.total_counting_row
                                      ?.fuel_amount
                                  }
                                </td>
                                <td>
                                  ₹ {salaryStatData?.total_counting_row?.other}
                                </td>
                                <td>
                                  ₹{' '}
                                  {
                                    salaryStatData?.total_counting_row
                                      ?.total_salary_without_others
                                  }
                                </td>
                                <td
                                  rowSpan={3}
                                  style={{
                                    fontWeight: 'bolder',
                                    color: 'black'
                                  }}
                                >
                                  <span>
                                    {costPerOrderCalculate(
                                      salaryStatData?.total_counting_row
                                        ?.points,
                                      salaryStatData?.total_counting_row
                                        ?.total_salary_without_others
                                    )}
                                    {' | '}
                                    {costPerOrderCalculate(
                                      salaryStatData?.total_counting_row
                                        ?.order_count,
                                      salaryStatData?.total_counting_row
                                        ?.total_salary_without_others
                                    )}
                                  </span>
                                  <hr className="my-2" />
                                  <span>
                                    {costPerOrderCalculate(
                                      salaryStatData?.total_counting_row
                                        ?.points,
                                      salaryStatData?.total_counting_row
                                        ?.total_salary
                                    )}
                                    {' | '}
                                    {costPerOrderCalculate(
                                      salaryStatData?.total_counting_row
                                        ?.order_count,
                                      salaryStatData?.total_counting_row
                                        ?.total_salary
                                    )}
                                    <br />
                                    (Including Others)
                                  </span>
                                </td>
                              </tr>
                              <tr
                                className="bg-light footer-row-rider-stat"
                                style={{ fontWeight: 'bolder', color: 'black' }}
                              >
                                <td colSpan={5}>
                                  {_.upperCase('average calculation')}
                                </td>
                                <td>
                                  {salaryStatData?.avg_counting_row?.duration}
                                </td>
                                <td>
                                  {salaryStatData?.avg_counting_row?.points} |{' '}
                                  {
                                    salaryStatData?.avg_counting_row
                                      ?.order_count
                                  }
                                </td>
                                <td>
                                  ₹{' '}
                                  {
                                    salaryStatData?.avg_counting_row
                                      ?.base_salary
                                  }
                                </td>
                                <td>
                                  ₹{' '}
                                  {salaryStatData?.avg_counting_row?.incentive}
                                </td>
                                <td>
                                  ₹{' '}
                                  {
                                    salaryStatData?.avg_counting_row
                                      ?.fuel_amount
                                  }
                                </td>
                                <td>
                                  ₹ {salaryStatData?.avg_counting_row?.other}
                                </td>
                                <td>
                                  ₹{' '}
                                  {
                                    salaryStatData?.avg_counting_row
                                      ?.total_salary_without_others
                                  }
                                </td>
                              </tr>
                              <tr
                                className="bg-light footer-row-rider-stat"
                                style={{ fontWeight: 'bolder', color: 'black' }}
                              >
                                <td colSpan={5}>
                                  {_.upperCase('points analytics')}
                                </td>
                                <td>
                                  {pointAnalyticsCalculation(
                                    salaryStatData?.total_counting_row.duration,
                                    salaryStatData?.total_counting_row.points
                                  )}
                                </td>
                                <td>NA</td>
                                <td>
                                  ₹{' '}
                                  {pointAnalyticsCalculation(
                                    salaryStatData?.total_counting_row
                                      .base_salary,
                                    salaryStatData?.total_counting_row.points
                                  )}
                                </td>
                                <td>
                                  ₹{' '}
                                  {pointAnalyticsCalculation(
                                    salaryStatData?.total_counting_row
                                      .incentive,
                                    salaryStatData?.total_counting_row.points
                                  )}
                                </td>
                                <td>
                                  ₹{' '}
                                  {pointAnalyticsCalculation(
                                    salaryStatData?.total_counting_row
                                      .fuel_amount,
                                    salaryStatData?.total_counting_row.points
                                  )}
                                </td>
                                <td>
                                  ₹{' '}
                                  {pointAnalyticsCalculation(
                                    salaryStatData?.total_counting_row.other,
                                    salaryStatData?.total_counting_row.points
                                  )}
                                </td>
                                <td>
                                  ₹{' '}
                                  {pointAnalyticsCalculation(
                                    salaryStatData?.total_counting_row
                                      .total_salary_without_others,
                                    salaryStatData?.total_counting_row.points
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(RiderSalaryStatistics);
