import React, { useState, useRef, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormGroup,
  Label,
  Button,
  Input
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Select from 'react-select';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';
import validator from 'validator';
import _ from 'lodash';

const RegisterNewTPShop = ({ myLoginState }) => {
  const admin_id = JSON.parse(localStorage.getItem('user_details'))?.user_id;
  const city_id = JSON.parse(localStorage.getItem('city_details'))?._id;

  const history = useHistory();
  const [breadcrumbItems] = useState([
    { title: 'Third Party', link: '#' },
    { title: 'Register Third Party', link: '#' }
  ]);

  const [requesting, setRequesting] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [localityLoading, setLocalityLoading] = useState(true);
  const [CityLoading, setCityLoading] = useState(true);
  const [Cityid, setCityid] = useState("");
  const [localityOptions, setLocalityOptions] = useState([]);
  const [CityOptions, setCityOptions] = useState([]);
  const [selectedLocality, setSelectedLocality] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  let registerForm = useRef();

  const fetchLocality = async (selectedItem) => {
    setLocalityLoading(true);
    try {
      setAuthToken();
      const city_id= selectedItem.value._id;
      setCityid(city_id);
      const result = await sendRequest.get(
        `/city/${city_id}/setting?setting_type=locality`
      );
      setLocalityOptions(
        result.data.map((item) => ({
          value: item,
          label: `${item.locality_code} - ${item.locality_name} (${item.total_point})`
        }))
      );
    } catch (e) {
      console.log(e);
    }
    setLocalityLoading(false);
  };
  const fetchCity=async()=>{
    setCityLoading(true);
    try {
      setAuthToken();
      const result = await sendRequest.get(
        `/city`
      );
      setCityOptions(
        result.data.map((item) => ({
          value: item,
          label: `${item.city_name} - ${item.state_name}`
        }))
      );
    } catch (e) {
      console.log(e);
    }
    setCityLoading(false);
  }
  useEffect(() => {
    fetchCity();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (event, values) => {
    values.addr_line_1 = values.address_line_1;
    values.addr_line_2 = values.address_line_2;
    values.city_id=Cityid;
    values.locality_info = selectedLocality.value;

    setRequesting(true);
    try {
      setAuthToken();
      const result = await sendRequest.post(
        `/tp/register`,
        values
      );
      setIsSucceed(true);
      registerForm && registerForm.current.reset();
      setResponseMessage(result.data.message);
      setOpenResponseModal(true);
    } catch (e) {
      setIsSucceed(false);
      setResponseMessage(e?.response?.data?.error);
      setOpenResponseModal(true);
    }
    setRequesting(false);
  };

  const validatePhoneNumber = async (value, ctx, input, cb) => {
    if (!validator.isMobilePhone(value, 'en-IN')) {
      return cb('Please enter a valid Indian Mobile Number');
    }
    return cb(true);
  };

  const handleLocalitySelection = (selectedItem) => {
    setSelectedLocality(selectedItem);
  };
  const handleCitySelection = (selectedItem) => {
    setSelectedCity(selectedItem);
    fetchLocality(selectedItem);
  };

  return (
    <React.Fragment>
      {openResponseModal && (
        <SweetAlert
          success={isSucceed}
          error={!isSucceed}
          title={isSucceed ? 'Registered' : 'Error'}
          onConfirm={() => {
            setOpenResponseModal(false);
            if (isSucceed) {
              history.push('/tp/list');
            }
          }}
        >
          {responseMessage}
        </SweetAlert>
      )}

      <div className="page-content" style={{ minHeight: '100vh' }}>
        <Container fluid>
          <Breadcrumbs
            title="Register Third Party"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="mb-3">Third Party Information</h4>
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={handleSubmit}
                    ref={registerForm}
                  >
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>Owner Name</Label>
                          <AvField
                            name="owner_name"
                            placeholder="Shop Owner Name"
                            type="text"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'Shop owner name is required'
                              },
                              pattern: {
                                value: `^[A-Za-z ]+$`,
                                errorMessage:
                                  'Shop owner name must be composed only with letter'
                              }
                            }}
                            disabled={requesting}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Phone Number</Label>
                          <AvField
                            name="phone_number"
                            placeholder="Phone Number"
                            type="text"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'Phone Number is required'
                              },
                              minLength: {
                                value: 10,
                                errorMessage: 'Phone Number must be 10 digits'
                              },
                              maxLength: {
                                value: 10,
                                errorMessage: 'Phone Number must be 10 digits'
                              },
                              async: validatePhoneNumber
                            }}
                            disabled={requesting}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>Address Line 1</Label>
                          <AvField
                            name="address_line_1"
                            placeholder="Hosue No / Flat Number / Shop No etc"
                            type="text"
                            className="form-control"
                            disabled={requesting}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <Label>Address Line 2</Label>
                          <AvField
                            name="address_line_2"
                            placeholder="Building Name / Area Name etc"
                            type="text"
                            className="form-control"
                            disabled={requesting}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                    <Col md="6">
                      <FormGroup>
                          <Label>City</Label>
                          <Select
                            options={CityOptions}
                            disabled={requesting}
                            onChange={handleCitySelection}
                            value={selectedCity}
                            isClearable={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Locality</Label>
                          <Select
                            options={localityOptions}
                            disabled={requesting}
                            onChange={handleLocalitySelection}
                            value={selectedLocality}
                            isClearable={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={
                        requesting || localityLoading || !selectedLocality
                      }
                    >
                      {requesting ? 'Loading ...' : 'Register'}
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const myLoginState = state.Login;
  return { myLoginState };
};

export default connect(mapStateToProps)(RegisterNewTPShop);
