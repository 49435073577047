import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { CHECK_LOGIN, LOGOUT_USER, LOAD_USER } from './actionTypes';
import { apiError, loginUserSuccessful, loadUserSuccessful } from './actions';
import { loginRequest, getAuthenticatedUser } from '../../helpers/auth-requests';

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history } }) {
  try {
    const data = yield call(loginRequest, '/auth/login', {
      phone_number: user.phone_number,
      password: user.password,
    });

    localStorage.setItem('ACCESS_TOKEN', data.responses.access_token);
    localStorage.setItem('user_details', JSON.stringify(data.responses.user_details));

    yield put(loginUserSuccessful(data.responses));
    history.push('/dashboard');
    window.location.reload();
  } catch (e) {
    yield put(apiError(e.error));
  }
}

function* loadUser() {
  try {
    const response_data = yield call(getAuthenticatedUser, '/auth/verify');
    if (response_data) {
      localStorage.setItem('user_details', JSON.stringify(response_data.user_details));
      yield put(loadUserSuccessful(response_data));
    } else yield put(apiError(''));
  } catch (e) {
    localStorage.removeItem('ACCESS_TOKEN');
    yield put(apiError('Session Expired. Kindly login again'));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem('ACCESS_TOKEN');
    history.push('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(CHECK_LOGIN, loginUser);
}

export function* watchLoggedInUser() {
  yield takeEvery(LOAD_USER, loadUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* loginSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout), fork(watchLoggedInUser)]);
}

export default loginSaga;
