import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormGroup,
  Label,
  Input,
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Alert,
} from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from 'react-loader-spinner';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';
import moment from 'moment';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SweetAlert from 'react-bootstrap-sweetalert';

const CityDetails = () => {
  const { city_code, city_id } = useParams();
  const history = useHistory();
  const [breadcrumbItems] = useState([
    { title: 'City', link: '/' },
    { title: 'City Details', link: '#' },
    { title: city_code, link: '#' },
  ]);
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState(null);
  const [orderSettingUpdating, setOrderSettingUpdating] = useState(false);
  const [orderSettingModalOpen, setOrderSettingModalOpen] = useState(false);
  const [orderAcceptStatus, setOrderAcceptStatus] = useState({
    admin_all_order: true,
    admin_schedule_order: true,
    app_all_order: true,
    app_schedule_order: true,
    app_all_order_message: true,
    app_schedule_order_message: true,
  });
  const [responseAlert, setResponseAlert] = useState({
    isOpen: false,
    success: false,
    error: false,
    message: '',
  });

  useEffect(() => {
    fetchGeneralCitySetting();
    // eslint-disable-next-line
  }, []);

  async function fetchGeneralCitySetting() {
    setDataLoading(true);
    try {
      setAuthToken();
      const { data: city_data } = await sendRequest.get(`/city/${city_id}`);
      setData(city_data);
      setOrderAcceptStatus(city_data.setting_details.order_request_accept);
    } catch (e) {
      console.log(e);
    }
    setDataLoading(false);
  }

  function resetOrderSettingModal() {
    setOrderSettingModalOpen(false);
    resetResponseAlert();
  }
  function resetResponseAlert() {
    setResponseAlert({
      isOpen: false,
      success: false,
      error: false,
      message: '',
    });
  }

  async function updateOrderSettingHandler(event, values) {
    setOrderSettingUpdating(true);
    try {
      setAuthToken();
      const { data: response } = await sendRequest.put(`/city/${city_id}/order_setting`, {
        ...orderAcceptStatus,
        app_all_order_message: values.app_all_order_message,
        app_schedule_order_message: values.app_schedule_order_message,
      });
      setOrderSettingModalOpen(false);
      setResponseAlert({
        isOpen: true,
        success: true,
        error: false,
        message: response.message,
      });
    } catch (e) {
      console.log(e);
      setResponseAlert({
        isOpen: false,
        success: false,
        error: true,
        message: e.response?.data?.error,
      });
    }
    setOrderSettingUpdating(false);
  }

  return (
    <React.Fragment>
      {responseAlert.isOpen && (
        <SweetAlert
          success
          title='Updated'
          onConfirm={() => {
            resetOrderSettingModal();
            fetchGeneralCitySetting();
          }}
        >
          {responseAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={orderSettingModalOpen} backdrop='static' size='lg'>
        <ModalHeader
          toggle={() => {
            if (!orderSettingUpdating) {
              resetOrderSettingModal();
            }
          }}
        >
          Order Setting
        </ModalHeader>
        <ModalBody>
          <Alert
            color='danger'
            role='alert'
            isOpen={responseAlert.error}
            toggle={resetResponseAlert}
          >
            <i className='mdi mdi-block-helper mr-2'></i>
            {responseAlert.message}
          </Alert>
          <AvForm className='needs-validation' onValidSubmit={updateOrderSettingHandler}>
            <Row>
              <Col md='3'>
                <FormGroup>
                  <Label>App Orders (ALL)</Label>
                  <div className='custom-control custom-switch mt-2' dir='ltr'>
                    <Input
                      type='checkbox'
                      className='custom-control-input'
                      id='app_all_order'
                      onChange={() => {}}
                      checked={orderAcceptStatus.app_all_order}
                      color='danger'
                    />
                    <Label
                      className={`custom-control-label text-${
                        orderAcceptStatus.app_all_order ? 'dark' : 'danger'
                      }`}
                      htmlFor='app_all_order'
                      onClick={() => {
                        setOrderAcceptStatus({
                          ...orderAcceptStatus,
                          app_all_order: !orderAcceptStatus.app_all_order,
                        });
                      }}
                    >
                      {orderAcceptStatus.app_all_order ? 'ON' : 'OFF'}
                    </Label>
                  </div>
                </FormGroup>
              </Col>
              <Col md={9}>
                <FormGroup>
                  <Label>Reason</Label>
                  <AvField
                    name='app_all_order_message'
                    placeholder='Reason ...'
                    type='text'
                    className='form-control'
                    validate={{
                      required: {
                        value: !orderAcceptStatus.app_all_order,
                        errorMessage: 'Reason is required',
                      },
                    }}
                    value={orderAcceptStatus.app_all_order_message}
                    disabled={orderSettingUpdating || orderAcceptStatus.app_all_order}
                  />
                </FormGroup>
              </Col>
              <Col md='3'>
                <FormGroup>
                  <Label>App Orders (Schedule)</Label>
                  <div className='custom-control custom-switch mt-2' dir='ltr'>
                    <Input
                      type='checkbox'
                      className='custom-control-input'
                      id='app_schedule_order'
                      onChange={() => {}}
                      checked={
                        orderAcceptStatus.app_all_order && orderAcceptStatus.app_schedule_order
                      }
                      color='danger'
                    />
                    <Label
                      className={`custom-control-label text-${
                        orderAcceptStatus.app_all_order && orderAcceptStatus.app_schedule_order
                          ? 'dark'
                          : 'danger'
                      }`}
                      htmlFor='app_schedule_order'
                      onClick={() => {
                        setOrderAcceptStatus({
                          ...orderAcceptStatus,
                          app_schedule_order: !orderAcceptStatus.app_schedule_order,
                        });
                      }}
                    >
                      {orderAcceptStatus.app_all_order && orderAcceptStatus.app_schedule_order
                        ? 'ON'
                        : 'OFF'}
                    </Label>
                  </div>
                </FormGroup>
              </Col>
              <Col md={9}>
                <FormGroup>
                  <Label>Reason</Label>
                  <AvField
                    name='app_schedule_order_message'
                    placeholder='Reason ...'
                    type='text'
                    className='form-control'
                    validate={{
                      required: {
                        value:
                          orderAcceptStatus.app_all_order && !orderAcceptStatus.app_schedule_order,
                        errorMessage: 'Reason is required',
                      },
                    }}
                    value={orderAcceptStatus.app_schedule_order_message}
                    disabled={orderSettingUpdating || orderAcceptStatus.app_schedule_order}
                  />
                </FormGroup>
              </Col>
              <Col md='3'>
                <FormGroup>
                  <Label>Admin Orders (ALL)</Label>
                  <div className='custom-control custom-switch mt-2' dir='ltr'>
                    <Input
                      type='checkbox'
                      className='custom-control-input'
                      id='admin_all_order'
                      onChange={() => {}}
                      checked={orderAcceptStatus.admin_all_order}
                      color='danger'
                    />
                    <Label
                      className={`custom-control-label text-${
                        orderAcceptStatus.admin_all_order ? 'dark' : 'danger'
                      }`}
                      htmlFor='admin_all_order'
                      onClick={() => {
                        setOrderAcceptStatus({
                          ...orderAcceptStatus,
                          admin_all_order: !orderAcceptStatus.admin_all_order,
                        });
                      }}
                    >
                      {orderAcceptStatus.admin_all_order ? 'ON' : 'OFF'}
                    </Label>
                  </div>
                </FormGroup>
              </Col>
              <Col md='3'>
                <FormGroup>
                  <Label>Admin Orders (Schedule)</Label>
                  <div className='custom-control custom-switch mt-2' dir='ltr'>
                    <Input
                      type='checkbox'
                      className='custom-control-input'
                      id='admin_schedule_order'
                      onChange={() => {}}
                      checked={
                        orderAcceptStatus.admin_all_order && orderAcceptStatus.admin_schedule_order
                      }
                      color='danger'
                    />
                    <Label
                      className={`custom-control-label text-${
                        orderAcceptStatus.admin_all_order && orderAcceptStatus.admin_schedule_order
                          ? 'dark'
                          : 'danger'
                      }`}
                      htmlFor='admin_schedule_order'
                      onClick={() => {
                        setOrderAcceptStatus({
                          ...orderAcceptStatus,
                          admin_schedule_order: !orderAcceptStatus.admin_schedule_order,
                        });
                      }}
                    >
                      {orderAcceptStatus.admin_all_order && orderAcceptStatus.admin_schedule_order
                        ? 'ON'
                        : 'OFF'}
                    </Label>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <ModalFooter>
              <Button
                type='button'
                color='light'
                size='sm'
                onClick={resetOrderSettingModal}
                disabled={orderSettingUpdating}
              >
                Close
              </Button>
              <Button type='submit' size='sm' color='success' disabled={orderSettingUpdating}>
                {orderSettingUpdating ? 'Loading ...' : 'Update'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='View City Details' breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                {dataLoading ? (
                  <div className='text-center my-3'>
                    <Loader type='TailSpin' color='#d3932c' height={80} width={80} />
                  </div>
                ) : !dataLoading && !data ? (
                  <CardBody>
                    <h4 className='text-center'>No Data Found</h4>
                  </CardBody>
                ) : (
                  <CardBody>
                    <Row>
                      <Col md='6'>
                        <h5 className='text-capitalize mb-3'>
                          City Information
                          <Badge
                            pill
                            className={`badge-soft-${
                              data.is_active ? 'success' : 'danger'
                            } font-size-14 ml-2`}
                          >
                            {`${data.is_active ? 'ACTIVE' : 'NOT ACTIVE'}`}
                          </Badge>
                        </h5>
                        <Row>
                          <Col md='6'>
                            <FormGroup>
                              <Label>City Name</Label>
                              <Input
                                type='text'
                                className='form-control'
                                readOnly
                                value={data?.city_name}
                              />
                            </FormGroup>
                          </Col>
                          <Col md='6'>
                            <FormGroup>
                              <Label>State Name</Label>
                              <Input
                                type='text'
                                className='form-control'
                                readOnly
                                value={data?.state_name}
                              />
                            </FormGroup>
                          </Col>
                          <Col md='6'>
                            <FormGroup>
                              <Label>City Code</Label>
                              <Input
                                type='text'
                                className='form-control'
                                readOnly
                                value={`C${data?.city_seq_code}`}
                              />
                            </FormGroup>
                          </Col>
                          <Col md='6'>
                            <FormGroup>
                              <Label>Registration Date</Label>
                              <Input
                                type='text'
                                className='form-control'
                                readOnly
                                value={moment(data.createdAt).format('DD-MM-YYYY')}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      {data?.is_active ? (
                        <Col md='6'>
                          <h5 className='text-capitalize mb-3'>General Settings</h5>
                          <Row>
                            <Col md='12'>
                              <FormGroup>
                                <Label>Center Name</Label>
                                <Input
                                  type='text'
                                  className='form-control'
                                  readOnly
                                  value={data?.setting_details?.center_point.place_name}
                                />
                              </FormGroup>
                            </Col>
                            <Col md='6'>
                              <FormGroup>
                                <Label>Center Longitude</Label>
                                <Input
                                  type='text'
                                  className='form-control'
                                  readOnly
                                  value={data?.setting_details?.center_point.coordinates[0]}
                                />
                              </FormGroup>
                            </Col>
                            <Col md='6'>
                              <FormGroup>
                                <Label>Center Latitude</Label>
                                <Input
                                  type='text'
                                  className='form-control'
                                  readOnly
                                  value={data?.setting_details?.center_point.coordinates[1]}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <>
                          <Col md='6' className='d-flex justify-content-center align-items-center'>
                            <Button
                              color='primary'
                              className='waves-effect waves-light'
                              onClick={() => {
                                history.push(`/city/${city_id}/${city_code}/settings/general/add`);
                              }}
                            >
                              Add City Settings
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>
                    {data?.is_active ? (
                      <>
                        <hr className='my-3' />

                        <Row>
                          <Col md='6'>
                            <h5 className='text-capitalize mb-3'>Vehicle Points</h5>
                            <Row>
                              <Col md='4'>
                                <FormGroup>
                                  <Label>Bike</Label>
                                  <Input
                                    type='text'
                                    className='form-control'
                                    readOnly
                                    value={data?.setting_details?.vehicle_points[0]?.point}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md='4'>
                                <FormGroup>
                                  <Label>Scooter</Label>
                                  <Input
                                    type='text'
                                    className='form-control'
                                    readOnly
                                    value={data?.setting_details?.vehicle_points[1]?.point}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md='4'>
                                <FormGroup>
                                  <Label>Scooter (Extra Care)</Label>
                                  <Input
                                    type='text'
                                    className='form-control'
                                    readOnly
                                    value={data?.setting_details?.vehicle_points[2]?.point}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                          <Col md='6'>
                            <h5 className='text-capitalize mb-3'>Additional Settings</h5>
                            <Row>
                              <Col md='6'>
                                <FormGroup>
                                  <Label>Cost per Point</Label>
                                  <Input
                                    type='text'
                                    className='form-control'
                                    readOnly
                                    value={data?.setting_details?.cost_per_point}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md='6'>
                                <FormGroup>
                                  <Label>Auto Assign Mode</Label>
                                  <Input
                                    type='text'
                                    className='form-control'
                                    readOnly
                                    value={
                                      data?.setting_details?.auto_assign_mode_status ? 'ON' : 'OFF'
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <hr className='my-3' />
                        <h5 className='text-capitalize mb-3'>Range Selection (Km)</h5>
                        <Row>
                          {data?.setting_details?.ranges?.map((item, index) => (
                            <Col md='2' key={index}>
                              <FormGroup>
                                <Label>Range {index + 1}</Label>
                                <Input
                                  type='number'
                                  className='form-control'
                                  value={item.range_dist}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                          ))}
                        </Row>
                      </>
                    ) : null}
                    {data?.is_active ? (
                      <>
                        <hr className='my-3' />
                        <Row className=''>
                          <Col lg='12'>
                            <Button
                              color='primary'
                              size='sm'
                              className=' waves-effect waves-light float-right mr-3'
                              onClick={() => {
                                history.push(`/city/${city_id}/${city_code}/settings/general`);
                              }}
                            >
                              Edit General Settings
                            </Button>
                            <Button
                              color='primary'
                              size='sm'
                              className=' waves-effect waves-light float-right mr-3'
                              onClick={() => {
                                history.push(`/city/${city_id}/${city_code}/settings/membership`);
                              }}
                            >
                              Membership
                            </Button>
                            <Button
                              color='primary'
                              size='sm'
                              className=' waves-effect waves-light float-right mr-3'
                              onClick={() => {
                                history.push(`/city/${city_id}/${city_code}/settings/validity`);
                              }}
                            >
                              Validity
                            </Button>
                            <Button
                              color='primary'
                              size='sm'
                              className='waves-effect waves-light float-right mr-3'
                              onClick={() => {
                                history.push(`/city/${city_id}/${city_code}/settings/locality`);
                              }}
                            >
                              Locality
                            </Button>
                            <Button
                              color='primary'
                              size='sm'
                              className=' waves-effect waves-light float-right mr-3'
                              onClick={() => {
                                history.push(`/city/${city_id}/${city_code}/settings/salary`);
                              }}
                            >
                              Rider Salary
                            </Button>
                            <Button
                              color='primary'
                              size='sm'
                              className='waves-effect waves-light float-right mr-3'
                              onClick={() => {
                                history.push(`/city/${city_id}/${city_code}/admin`);
                              }}
                            >
                              City Admin
                            </Button>
                            <Button
                              color='primary'
                              size='sm'
                              className='waves-effect waves-light float-right mr-3'
                              onClick={() => {
                                history.push(`/city/${city_id}/${city_code}/payments`);
                              }}
                            >
                              Payment Details
                            </Button>
                            <Button
                              color='info'
                              size='sm'
                              className='waves-effect waves-light float-right mr-3'
                              onClick={() => {
                                setOrderSettingModalOpen(true);
                              }}
                            >
                              Order Setting
                            </Button>
                            <Button
                              color='info'
                              size='sm'
                              className='waves-effect waves-light float-right mr-3'
                              onClick={() => {
                                history.push(
                                  `/city/${city_id}/${city_code}/statistics/rider_salary`
                                );
                              }}
                            >
                              Rider Statistics
                            </Button>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CityDetails;
