import moment from 'moment';

async function saveFileToLocal(fileObj, fileName) {
  const current_time_tag = moment().format('YYYY-MM-DD-HH-mm');
  var a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  const url = window.URL.createObjectURL(fileObj);
  a.href = url;
  a.download = `${fileName}-${current_time_tag}.csv`;
  a.click();
  window.URL.revokeObjectURL(url);
}

export { saveFileToLocal };
