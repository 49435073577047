import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormGroup,
  Label,
  Input,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from 'react-loader-spinner';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';

const ShopProfileView = ({ myLoginState }) => {
  const {tp_id } = useParams();
  const history = useHistory();

  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: 'TP', link: `/tp/list`, index: 1 },
    { title: 'Profile View', link: '#', index: 2 },
  ]);
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState(null);
  const [lightBox, setLightBox] = useState(null);
  const [approving, setApproving] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState({
    execute: false,
    message: ''
  });
  const [rejectModal, setRejectModal] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [approveErrorAlert, setApproveErrorAlert] = useState({
    error: null,
    message: null
  });
  const [removing, setRemoving] = useState(false);
  const [removeResponse, setRemoveResponse] = useState({
    error: null,
    success: null,
    message: '',
    modalOpen: false
  });
  const [modalData, setModalData] = useState({});
  const [rejectionAlert, setRejectionAlert] = useState(null);
  const [approveAlert, setApproveAlert] = useState({});
  const [unblocking, setUnblocking] = useState(false);
  const [sendingNotification, setSendingNotification] = useState(false);

  useEffect(() => {
    setAuthToken();
    sendRequest
      .get(`/tp/${tp_id}`)
      .then((result) => {
        setData(result.data);
        setBreadcrumbItems(
          breadcrumbItems.map((it) => {
            if (it.index === 3) {
              return {
                ...it,
                title: result.data.shop_code
              };
            } else return it;
          })
        );
        history.replace(
          `/tp/profile/view/${result.shop._id}/${result.shop.shop_code}`
        );
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      {lightBox && (
        <Lightbox
          mainSrc={data?.[lightBox.document_name]?.public_url}
          enableZoom={false}
          imageCaption={lightBox.caption}
          onCloseRequest={() => setLightBox(null)}
        />
      )}
      {/* {data && openErrorModal && (
        <SweetAlert
          error
          title={rejectionAlert?.title}
          onConfirm={() => {
            setOpenErrorModal(false);
            history.push('/shops/list');
          }}
        >
          {rejectionAlert?.message}
        </SweetAlert>
      )}

      <Modal isOpen={rejectModal} backdrop="static">
        <ModalHeader
          toggle={() => {
            if (!rejecting) setRejectModal(false);
          }}
        >
          {modalData.title}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="needs-validation"
            onValidSubmit={modalData.triggerFn}
          >
            <Row>
              <Col md={12}>
                <FormGroup>
                  <AvField
                    name="admin_declined_reason"
                    placeholder={modalData.placeholder}
                    type="text"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Reason is required'
                      }
                    }}
                    disabled={rejecting}
                  />
                </FormGroup>
              </Col>
            </Row>

            <ModalFooter>
              <Button
                type="button"
                color="light"
                onClick={() => {
                  setRejectModal(false);
                }}
                disabled={rejecting}
              >
                Close
              </Button>
              <Button type="submit" color="danger" disabled={rejecting}>
                Submit
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal> */}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Third Party Profile View"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xs={12}>
              <Card>
                {dataLoading ? (
                  <div className="text-center my-3">
                    <Loader
                      type="TailSpin"
                      color="#d3932c"
                      height={80}
                      width={80}
                    />
                  </div>
                ) : !dataLoading && !data ? (
                  <CardBody>
                    <h4 className="text-center">No Data Found</h4>
                  </CardBody>
                ) : (
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <h5 className="text-capitalize mb-3">
                          Shop Information
                          <Badge pill className="badge-soft-success ml-3">
                            {_.capitalize(data.shop.registered_via)}
                          </Badge>
                          {data.shop.is_blocked.value && (
                            <Badge pill className="badge-soft-warning ml-3">
                              Blocked
                            </Badge>
                          )}
                          {data.shop.is_membership_paused && (
                            <Badge pill className="badge-soft-danger ml-3">
                              Paused
                            </Badge>
                          )}
                          {data.shop.is_new_locality && (
                            <Badge pill className="badge-soft-secondary ml-3">
                              New Locality
                            </Badge>
                          )}
                        </h5>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label>Shop Name</Label>
                              <Input
                                type="text"
                                className="form-control"
                                readOnly
                                value={data?.shop.shop_name}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Shop Code</Label>
                              <Input
                                type="text"
                                className="form-control"
                                readOnly
                                value={data?.shop.shop_code}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Phone Number</Label>
                              <Input
                                type="text"
                                className="form-control"
                                readOnly
                                value={data?.shop.phone_number}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Owner Name</Label>
                              <Input
                                type="text"
                                className="form-control"
                                readOnly
                                value={data?.shop.owner_name}
                              />
                            </FormGroup>
                          </Col>
                          {data?.email_address && (
                            <Col md="6">
                              <FormGroup>
                                <Label>Email Address</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  value={data?.shop.email_address}
                                />
                              </FormGroup>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label>PRODUCTION API KEY</Label>
                              <Input
                                type="text"
                                className="form-control"
                                readOnly
                                value={data?.production_apikey}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>STAGING API KEY</Label>
                              <Input
                                type="text"
                                className="form-control"
                                readOnly
                                value={data?.staging_apikey}
                              />
                            </FormGroup>
                          </Col>
                          </Row>
                      </Col>
                      <Col md="6">
                        <h5 className="text-capitalize mb-3">
                          Address Details
                          <Badge
                            pill
                            className={`badge-soft-${
                              data?.shop.address?.traced_by === 'forward_geocode'
                                ? 'warning'
                                : 'success'
                            } ml-3`}
                          >
                            {data?.shop.address?.traced_by === 'forward_geocode'
                              ? 'Approximation'
                              : 'GPS'}
                          </Badge>
                          {data?.shop.address?.traced_by === 'reverse_geocode' && (
                            <Badge className={`badge-soft-info ml-3 px-2`}>
                              <a
                                href={`https://www.google.com/maps/place/${data?.shop.address?.coordinates[1]},${data?.shop.address?.coordinates[0]}`}
                                className="text-info"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Location{' '}
                                <i className="ri-share-box-line align-middle ml-1"></i>
                              </a>
                            </Badge>
                          )}
                        </h5>
                        <Row>
                          {data?.shop.address?.kd_details?.address_line_1 && (
                            <Col md="6">
                              <FormGroup>
                                <Label>Address Line 1</Label>
                                <Input
                                  name="address_line_1"
                                  placeholder="Hosue No / Flat Number / Shop No etc"
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  value={data.shop.address.kd_details.address_line_1}
                                />
                              </FormGroup>
                            </Col>
                          )}

                          {data?.shop.address?.kd_details?.address_line_2 && (
                            <Col md="6">
                              <FormGroup>
                                <Label>Address Line 2</Label>
                                <Input
                                  name="address_line_2"
                                  placeholder="Building Name / Area Name etc"
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  value={data.shop.address.kd_details.address_line_2}
                                />
                              </FormGroup>
                            </Col>
                          )}
                          <Col md="6">
                            <FormGroup>
                              <Label>Locality</Label>
                              <Input
                                name="locality_name"
                                type="text"
                                className="form-control"
                                readOnly
                                value={data?.shop.address?.locality_info?.name}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>City</Label>
                              <Input
                                name="city"
                                placeholder="City"
                                type="text"
                                value={_.capitalize(
                                  data?.shop.address?.formatted_details?.city
                                )}
                                className="form-control"
                                readOnly
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <Label>Formatted Address Details</Label>
                              <Input
                                className="form-control"
                                type="text"
                                disabled
                                defaultValue={
                                  data?.shop.address?.formatted_details
                                    ?.single_line_address
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const myLoginState = state.Login;
  return { myLoginState };
};

export default connect(mapStateToProps)(ShopProfileView);
