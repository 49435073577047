import React, { useState, useEffect } from 'react';
import { Row, Col, Container, FormGroup, Button, Label, Card, CardBody } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Loader from 'react-loader-spinner';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useParams, useHistory } from 'react-router-dom';

function RiderSalarySetting() {
  const { city_code, city_id } = useParams();
  const [breadcrumbItems] = useState([
    { title: 'City', link: '/' },
    { title: 'City Details', link: '#' },
    { title: city_code, link: `/city/${city_id}/${city_code}` },
    { title: 'Salary Settings', link: '#' },
  ]);
  const history = useHistory();
  const [fetchingSetting, setFetchingSetting] = useState(true);
  const [settingInfo, setSettingInfo] = useState({});
  const [settingUpdating, setSettingUpdating] = useState(false);

  async function updateSetting(event, values) {
    setSettingUpdating(true);
    try {
      setAuthToken();
      await sendRequest.put(`/city/${city_id}/setting/rider_salary`, values);
      await fetchSetting();
    } catch (e) {
      console.log(e);
    }
    setSettingUpdating(false);
  }

  const fetchSetting = async () => {
    setFetchingSetting(true);
    try {
      setAuthToken();
      const { data: rider_salary_setting } = await sendRequest.get(
        `/city/${city_id}/setting?setting_type=rider_salary`
      );
      setSettingInfo(rider_salary_setting);
    } catch (e) {
      history.push(`/city/${city_id}/${city_code}`);
      console.log(e);
    }
    setFetchingSetting(false);
  };

  useEffect(() => {
    fetchSetting();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <div className='page-content'>
          <Breadcrumbs title='Setup Salary Settings' breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <AvForm className='needs-validation' onValidSubmit={updateSetting}>
                    <Row>
                      <Col xs={12}>
                        <h5 className='text-capitalize mb-3'>Incentive Slabs</h5>
                        <Row>
                          {fetchingSetting ? (
                            <Col md={12}>
                              <div className='text-center'>
                                <Loader type='TailSpin' color='#d3932c' height={80} width={80} />
                              </div>
                            </Col>
                          ) : (
                            <>
                              {settingInfo.rider_salary_slab?.map((item, index) => (
                                <Col md='4' key={index}>
                                  <Row className='border m-1 pt-2 border-secondary'>
                                    <Col md={6}>
                                      <FormGroup>
                                        <Label>Slab {index + 1} Point</Label>
                                        <AvField
                                          name={`slab_${index + 1}_point`}
                                          placeholder='Points (x10)'
                                          type='number'
                                          className='form-control'
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage: 'Point is required',
                                            },
                                          }}
                                          value={item?.upper_point_value}
                                          disabled={settingUpdating}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                      <FormGroup>
                                        <Label>Incentive</Label>
                                        <AvField
                                          name={`slab_${index + 1}_amount`}
                                          placeholder='Amount (INR)'
                                          type='number'
                                          className='form-control'
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage: 'Amount is required',
                                            },
                                            min: {
                                              value: 1,
                                              errorMessage: 'Amount must be greater than 0.',
                                            },
                                          }}
                                          value={item?.incentive_amount}
                                          disabled={settingUpdating}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              ))}
                            </>
                          )}
                        </Row>

                        <Row>
                          <Col md={6}>
                            <hr className='my-3' />
                            <h5 className='text-capitalize mb-3'>Default Base Pay</h5>
                            <Row>
                              {fetchingSetting ? (
                                <Col md={12}>
                                  <div className='text-center'>
                                    <Loader
                                      type='TailSpin'
                                      color='#d3932c'
                                      height={80}
                                      width={80}
                                    />
                                  </div>
                                </Col>
                              ) : (
                                <>
                                  <Col md='6'>
                                    <FormGroup>
                                      <AvField
                                        name='base_pay'
                                        placeholder='Amount in INR'
                                        type='number'
                                        className='form-control'
                                        validate={{
                                          required: {
                                            value: true,
                                            errorMessage: 'Default base pay is required',
                                          },
                                          min: {
                                            value: 1,
                                            errorMessage: 'Amount must be greater than 0.',
                                          },
                                        }}
                                        value={settingInfo?.rider_salary_default_base_pay}
                                        disabled={settingUpdating}
                                      />
                                    </FormGroup>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </Col>
                          <Col md={6}>
                            <hr className='my-3' />
                            <h5 className='text-capitalize mb-3'>Default Fuel Factor</h5>
                            <Row>
                              {fetchingSetting ? (
                                <Col md={12}>
                                  <div className='text-center'>
                                    <Loader
                                      type='TailSpin'
                                      color='#d3932c'
                                      height={80}
                                      width={80}
                                    />
                                  </div>
                                </Col>
                              ) : (
                                <>
                                  <Col md='6'>
                                    <FormGroup>
                                      <AvField
                                        name='fuel_factor'
                                        placeholder='eg. 1.6'
                                        type='number'
                                        className='form-control'
                                        validate={{
                                          required: {
                                            value: true,
                                            errorMessage: 'Default fuel factor is required',
                                          },
                                        }}
                                        value={settingInfo?.rider_default_fuel_factor}
                                        disabled={settingUpdating}
                                      />
                                    </FormGroup>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </Col>
                        </Row>

                        <hr className='my-3' />
                        <Button
                          type='submit'
                          color='success'
                          className=' waves-effect waves-light mr-1 float-right'
                          disabled={settingUpdating}
                          size='sm'
                        >
                          {settingUpdating ? 'Updating...' : 'Update'}
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default RiderSalarySetting;
