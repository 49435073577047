import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Row,
  Col,
  CardBody,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Alert,
  Table,
} from 'reactstrap';
import Loader from 'react-loader-spinner';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import validator from 'validator';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';
import moment from 'moment';
import { AvForm, AvField } from 'availity-reactstrap-validation';

const CityAdmin = () => {
  const { city_code, city_id } = useParams();
  const history = useHistory();

  const [breadcrumbItems] = useState([
    { title: 'City', link: '/' },
    { title: 'City Details', link: '#' },
    { title: city_code, link: `/city/${city_id}/${city_code}` },
    { title: 'City Admin', link: '#' },
  ]);
  const [dataLoading, setDataLoading] = useState(true);
  const [cityAdminData, setCityAdminData] = useState([]);
  const [newAdminModalOpen, setNewAdminModalOpen] = useState(false);
  const [addingNewAdmin, setAddingNewAdmin] = useState(false);
  const [selectedNewAdminRole, setSelectedNewAdminRole] = useState(null);
  const [adminRegistration, setAdminRegistration] = useState({
    error: null,
    message: null,
  });
  const [adminUpdateOps, setAdminUpdateOps] = useState({
    error: null,
    message: null,
  });
  const [currentEditAdmin, setCurrentEditAdmin] = useState(null);
  const [selectedEditAdminRole, setSelectedEditAdminRole] = useState(null);
  const [editAminModalOpen, setEditAminModalOpen] = useState(false);
  const [adminRoles] = useState([
    { label: 'Admin (Can edit City Settings)', value: 'admin' },
    { label: 'Employee', value: 'employee' },
  ]);
  const [updatingAdminDetails, setUpdatingAdminDetails] = useState(false);

  useEffect(() => {
    fetchCityAdminList();
    // eslint-disable-next-line
  }, []);

  const fetchCityAdminList = async () => {
    setDataLoading(true);
    try {
      setAuthToken();
      const { data: admin_list } = await sendRequest.get(`/city/${city_id}/admin`);
      setCityAdminData(admin_list);
      setDataLoading(false);
    } catch (e) {
      console.log(e);
      history.push(`/city/${city_id}/${city_code}`);
    }
  };

  const handleNewAdminRoleSelection = (item) => {
    setSelectedNewAdminRole(item);
  };

  const registerNewCityAdmin = async (event, values) => {
    setAddingNewAdmin(true);
    try {
      setAuthToken();
      await sendRequest.post(`/city/${city_id}/admin`, {
        phone_number: values.phone_number,
        name: values.admin_name,
        password: values.admin_password,
        admin_role: selectedNewAdminRole.value,
      });

      setSelectedNewAdminRole(null);
      setAddingNewAdmin(false);
      setNewAdminModalOpen(false);
      fetchCityAdminList();
    } catch (e) {
      let message = e?.response?.data?.error || 'Something went wrong!';
      if (Array.isArray(e?.response?.data?.details) && e?.response?.data?.details?.length > 0) {
        message = e.response.data.details[0].msg;
      }
      setAdminRegistration({
        error: true,
        message: message,
      });
      setAddingNewAdmin(false);
    }
  };

  const validatePhoneNumber = async (value, ctx, input, cb) => {
    if (value && !validator.isMobilePhone(value, 'en-IN')) {
      return cb('Please enter a valid Indian Mobile Number');
    }
    return cb(true);
  };

  const updateAdminDetailsHandler = async (event, values) => {
    setUpdatingAdminDetails(true);
    try {
      setAuthToken();
      await sendRequest.put(`/city/${city_id}/admin/${currentEditAdmin?._id}`, {
        phone_number: values.phone_number,
        name: values.admin_name,
        password: values.admin_password,
        admin_role: selectedEditAdminRole.value,
      });

      setSelectedEditAdminRole(null);
      setUpdatingAdminDetails(false);
      setEditAminModalOpen(false);
      fetchCityAdminList();
      setAdminUpdateOps({
        error: null,
        message: null,
      });
    } catch (e) {
      let message = e?.response?.data?.error || 'Something went wrong!';
      if (Array.isArray(e?.response?.data?.details) && e?.response?.data?.details?.length > 0) {
        message = e.response.data.details[0].msg;
      }
      setAdminUpdateOps({
        error: true,
        message: message,
      });
      setUpdatingAdminDetails(false);
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={newAdminModalOpen}
        toggle={() => {
          setNewAdminModalOpen(false);
        }}
      >
        <ModalHeader toggle={() => setNewAdminModalOpen(false)}>Add New City Admin</ModalHeader>
        <ModalBody>
          {adminRegistration?.error ? (
            <Alert
              color='danger'
              role='alert'
              isOpen={adminRegistration?.error}
              toggle={() => {
                setAdminRegistration({
                  error: null,
                  message: null,
                });
              }}
            >
              <i className='mdi mdi-block-helper mr-2'></i>
              {adminRegistration?.message}
            </Alert>
          ) : null}
          <AvForm className='needs-validation' onValidSubmit={registerNewCityAdmin}>
            <Row>
              <Col md='12'>
                <FormGroup>
                  <Label>Full Name</Label>
                  <AvField
                    name='admin_name'
                    placeholder='Enter Admin Name'
                    type='text'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Admin name is required',
                      },
                      pattern: {
                        value: `^[A-Za-z ]+$`,
                        errorMessage: 'Name must be composed only with letter',
                      },
                    }}
                    disabled={addingNewAdmin}
                  />
                </FormGroup>
              </Col>
              <Col md='12'>
                <FormGroup>
                  <Label>Phone Number</Label>
                  <AvField
                    name='phone_number'
                    placeholder='Phone Number'
                    type='text'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Phone Number is required',
                      },
                      minLength: {
                        value: 10,
                        errorMessage: 'Phone Number must be 10 digits',
                      },
                      maxLength: {
                        value: 10,
                        errorMessage: 'Phone Number must be 10 digits',
                      },
                      async: validatePhoneNumber,
                    }}
                    disabled={addingNewAdmin}
                  />
                </FormGroup>
              </Col>
              <Col md='12'>
                <FormGroup>
                  <Label>Password</Label>
                  <AvField
                    name='admin_password'
                    placeholder='Enter Admin Login Password'
                    type='password'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Password is required',
                      },
                      minLength: {
                        value: 6,
                        errorMessage: 'Password must be at least 6 characters long',
                      },
                    }}
                    disabled={addingNewAdmin}
                  />
                </FormGroup>
              </Col>
              <Col md='12'>
                <FormGroup>
                  <Label>Role</Label>
                  <Select
                    options={adminRoles}
                    disabled={addingNewAdmin}
                    onChange={handleNewAdminRoleSelection}
                    value={selectedNewAdminRole}
                    isClearable={true}
                  />
                </FormGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Button
                type='button'
                color='light'
                onClick={() => {
                  setNewAdminModalOpen(false);
                }}
                disabled={addingNewAdmin}
              >
                Close
              </Button>

              <Button
                type='submit'
                color='success'
                disabled={addingNewAdmin || !selectedNewAdminRole}
              >
                {addingNewAdmin ? 'Loading ...' : 'Add Admin'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={editAminModalOpen}
        toggle={() => {
          setEditAminModalOpen(false);
        }}
      >
        <ModalHeader toggle={() => setEditAminModalOpen(false)}>Edit Admin Details</ModalHeader>
        <ModalBody>
          {adminUpdateOps?.error ? (
            <Alert
              color='danger'
              role='alert'
              isOpen={adminUpdateOps?.error}
              toggle={() => {
                setAdminUpdateOps({
                  error: null,
                  message: null,
                });
              }}
            >
              <i className='mdi mdi-block-helper mr-2'></i>
              {adminUpdateOps?.message}
            </Alert>
          ) : null}
          <AvForm className='needs-validation' onValidSubmit={updateAdminDetailsHandler}>
            <Row>
              <Col md='12'>
                <FormGroup>
                  <Label>Full Name</Label>
                  <AvField
                    name='admin_name'
                    placeholder='Enter Admin Name'
                    type='text'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Admin name is required',
                      },
                      pattern: {
                        value: `^[A-Za-z ]+$`,
                        errorMessage: 'Name must be composed only with letter',
                      },
                    }}
                    disabled={updatingAdminDetails}
                    value={currentEditAdmin?.name}
                  />
                </FormGroup>
              </Col>
              <Col md='12'>
                <FormGroup>
                  <Label>Phone Number</Label>
                  <AvField
                    name='phone_number'
                    placeholder='Phone Number'
                    type='text'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Phone Number is required',
                      },
                      minLength: {
                        value: 10,
                        errorMessage: 'Phone Number must be 10 digits',
                      },
                      maxLength: {
                        value: 10,
                        errorMessage: 'Phone Number must be 10 digits',
                      },
                      async: validatePhoneNumber,
                    }}
                    disabled={updatingAdminDetails}
                    value={currentEditAdmin?.phone_number}
                  />
                </FormGroup>
              </Col>
              <Col md='12'>
                <FormGroup>
                  <Label>Password</Label>
                  <AvField
                    name='admin_password'
                    placeholder='Enter Admin Login Password'
                    type='password'
                    className='form-control'
                    validate={{
                      minLength: {
                        value: 6,
                        errorMessage: 'Password must be at least 6 characters long',
                      },
                    }}
                    disabled={updatingAdminDetails}
                  />
                </FormGroup>
              </Col>
              <Col md='12'>
                <FormGroup>
                  <Label>Role</Label>
                  <Select
                    options={adminRoles}
                    disabled={updatingAdminDetails}
                    onChange={(item) => {
                      setSelectedEditAdminRole(item);
                    }}
                    value={selectedEditAdminRole}
                    isClearable={true}
                  />
                </FormGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Button
                type='button'
                color='light'
                onClick={() => {
                  setEditAminModalOpen(false);
                }}
                disabled={updatingAdminDetails}
              >
                Close
              </Button>

              <Button
                type='submit'
                color='success'
                disabled={updatingAdminDetails || !selectedEditAdminRole}
              >
                {updatingAdminDetails ? 'Loading ...' : 'Update Details'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>

      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='View City Admin' breadcrumbItems={breadcrumbItems} />
        </Container>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                {dataLoading ? (
                  <div className='text-center'>
                    <Loader type='TailSpin' color='#d3932c' height={80} width={80} />
                  </div>
                ) : (
                  <>
                    <div className='mb-3 d-flex justify-content-end'>
                      <Button
                        color='primary'
                        size='sm'
                        className='waves-effect waves-light'
                        onClick={() => {
                          setNewAdminModalOpen(true);
                        }}
                      >
                        Add New Admin
                      </Button>
                    </div>

                    <div className='table-rep-plugin'>
                      <div className='table-responsive mb-0' data-pattern='priority-columns'>
                        <Table striped bordered responsive>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Phone Number</th>
                              <th>Role</th>
                              <th>Registration Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cityAdminData.map((item) => {
                              return (
                                <tr key={item._id} style={{ cursor: 'pointer' }}>
                                  <td>{item.name}</td>
                                  <td>{item.phone_number}</td>
                                  <td>{String(item.admin_role).toUpperCase()}</td>
                                  <td>{moment(item.createdAt).format('DD-MM-YYYY')}</td>
                                  <td>
                                    <Button
                                      color='info'
                                      size='sm'
                                      className='waves-effect waves-light'
                                      onClick={() => {
                                        setCurrentEditAdmin(item);
                                        setSelectedEditAdminRole(
                                          adminRoles.find((k) => k.value === item.admin_role)
                                        );
                                        setEditAminModalOpen(true);
                                      }}
                                    >
                                      Edit Details
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default CityAdmin;
