import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormGroup,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InputGroupText,
  InputGroup,
  InputGroupAddon,
  Input,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from 'react-loader-spinner';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';

const LocalitySettings = () => {
  const { city_code, city_id } = useParams();
  const [breadcrumbItems] = useState([
    { title: 'City', link: '/' },
    { title: 'City Details', link: '#' },
    { title: city_code, link: `/city/${city_id}/${city_code}` },
    { title: 'Locality Settings', link: '#' },
  ]);
  const history = useHistory();
  const [fetchingSetting, setFetchingSetting] = useState(true);
  const [settingUpdating, setSettingUpdating] = useState(false);
  const [localityList, setLocalityList] = useState([]);
  const [tableColumns] = useState([
    {
      dataField: 'locality_code',
      text: 'Locality Code',
      sort: false,
      editable: false,
    },
    {
      dataField: 'locality_name',
      text: 'Locality Name',
      sort: false,
    },
    {
      dataField: 'total_point',
      text: 'Total Delivery Points (Fixed)',
      sort: false,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: 'Points should be Numeric',
          };
        }
        if (newValue <= 0) {
          return {
            valid: false,
            message: 'Total point must be greater than 0',
          };
        }
        return true;
      },
    },
    {
      dataField: 'total_cost',
      text: 'Total Cost (INR)',
      sort: false,
      editable: false,
    },
  ]);
  const [newLocalityModalOpen, setNewLocalityModalOpen] = useState(false);
  const [hiddenRowKeys, setHiddenRowKeys] = useState([]);
  const [searching, setSearching] = useState(false);
  const [addingNewLocality, setAddingNewLocality] = useState(false);
  const [anythingUpdated, setAnythingUpdated] = useState(false);

  const updateLocalitySetting = async (event, values) => {
    setSettingUpdating(true);
    try {
      setAuthToken();
      await sendRequest.put(`/city/${city_id}/setting/locality`, localityList);
      await fetchLocalitySetting();
    } catch (e) {
      console.log(e);
    }
    setSettingUpdating(false);
  };

  const fetchLocalitySetting = async () => {
    setFetchingSetting(true);
    try {
      setAuthToken();
      const { data: locality_data } = await sendRequest.get(
        `/city/${city_id}/setting?setting_type=locality`
      );
      setLocalityList(locality_data);
    } catch (e) {
      console.log(e);
      history.push(`/city/${city_id}/${city_code}`);
    }
    setFetchingSetting(false);
  };

  useEffect(() => {
    fetchLocalitySetting();
    // eslint-disable-next-line
  }, []);

  const addNewLocality = async (event, values) => {
    setAddingNewLocality(true);
    const new_locality_list = [
      ...localityList,
      {
        locality_code: 'L' + Number(localityList.length + 1),
        locality_name: values.locality_name,
        total_point: values.total_point,
      },
    ];
    try {
      setAuthToken();
      await sendRequest.put(`/city/${city_id}/setting/locality`, new_locality_list);
      await fetchLocalitySetting();
      setNewLocalityModalOpen(false);
    } catch (e) {
      console.log(e);
    }
    setAddingNewLocality(false);
  };

  const updateCell = (oldValue, newValue, row, column) => {
    const cost_per_point = row.total_cost / row.total_point;
    const localityCode = row.locality_code;
    setLocalityList(
      localityList.map((it) => {
        if (it.locality_code === localityCode && column.dataField === 'total_point') {
          it.total_cost = Number(newValue) * Number(cost_per_point);
        }
        return it;
      })
    );
    setAnythingUpdated(true);
  };

  const searchLocality = (e) => {
    if (String(e.target.value).trim().length > 0) {
      setSearching(true);
    } else setSearching(false);
    const regexTester = new RegExp(e.target.value, 'gi');
    const keysToHide = [];
    for (let item of localityList) {
      if (!regexTester.test(item.locality_name)) {
        keysToHide.push(item.locality_code);
      }
    }
    setHiddenRowKeys(keysToHide);
  };

  return (
    <React.Fragment>
      <Modal isOpen={newLocalityModalOpen} backdrop='static' size='lg'>
        <ModalHeader
          toggle={() => {
            if (!addingNewLocality) setNewLocalityModalOpen(false);
          }}
        >
          Add New Locality
        </ModalHeader>
        <ModalBody>
          <AvForm className='needs-validation' onValidSubmit={addNewLocality}>
            <Row>
              <Col md='6'>
                <FormGroup>
                  <Label>Locality Name</Label>
                  <AvField
                    name='locality_name'
                    placeholder='Enter Locality Name'
                    type='text'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Locality name is required',
                      },
                    }}
                    disabled={addingNewLocality}
                  />
                </FormGroup>
              </Col>

              <Col md='6'>
                <FormGroup>
                  <Label>Total Fixed Point</Label>
                  <AvField
                    name='total_point'
                    placeholder='Enter total delivery point'
                    type='number'
                    className='form-control'
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Total point is required.',
                      },
                      min: {
                        value: 1,
                        errorMessage: 'Total point must be greater than 0.',
                      },
                    }}
                    disabled={addingNewLocality}
                  />
                </FormGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Button
                type='button'
                color='light'
                onClick={() => {
                  setNewLocalityModalOpen(false);
                }}
                disabled={addingNewLocality}
                size='sm'
              >
                Close
              </Button>
              <Button size='sm' type='submit' color='success' disabled={addingNewLocality}>
                {addingNewLocality ? 'Adding ...' : 'Add'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Setup City Locality' breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col md={9}>
                          <FormGroup>
                            <InputGroup>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                  <i className='ri-search-2-line'></i>{' '}
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                type='text'
                                className='form-control'
                                placeholder='Search Locality ...'
                                onChange={searchLocality}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <Button
                            color='success'
                            className=' waves-effect waves-light mr-1 float-right'
                            onClick={updateLocalitySetting}
                            disabled={settingUpdating || searching || !anythingUpdated}
                          >
                            {settingUpdating ? 'Updating...' : 'Update'}
                          </Button>
                          <Button
                            color='info'
                            className=' waves-effect waves-light mr-2 float-right'
                            onClick={() => {
                              setNewLocalityModalOpen(true);
                            }}
                            disabled={settingUpdating}
                          >
                            Add New Locality
                          </Button>
                        </Col>
                      </Row>
                      <hr className='my-3' />
                      {fetchingSetting ? (
                        <div className='text-center'>
                          <Loader type='TailSpin' color='#d3932c' height={80} width={80} />
                        </div>
                      ) : (
                        <div className='table-responsive'>
                          <BootstrapTable
                            keyField='locality_code'
                            data={localityList}
                            columns={tableColumns}
                            cellEdit={cellEditFactory({
                              mode: 'click',
                              blurToSave: true,
                              beforeSaveCell: updateCell,
                              afterSaveCell: updateCell,
                            })}
                            hiddenRows={hiddenRowKeys}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LocalitySettings;
