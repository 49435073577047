import React from 'react';
import { Redirect } from 'react-router-dom';

/**
 *
 * Authentication
 */
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';

/**
 *
 * Dashboard
 */
import Dashboard from '../pages/Dashboard/index';

/**
 *
 * City
 */
import CityDetails from '../pages/City/CityDetails';
import AddCitySettings from '../pages/City/AddCitySetting';
import EditCitySettings from '../pages/City/EditCitySetting';
import MembershipSettings from '../pages/City/MembershipSetting';
import LocalitySettings from '../pages/City/LocalitySetting';
import CityAdmin from '../pages/City/CityAdmin';
import PaymentDetails from '../pages/City/PaymentDetails';
import ValiditySetting from '../pages/City/ValiditySetting';
import RiderSalarySetting from '../pages/City/SalarySetting';
import CityNotificationControl from '../pages/City/Notification';

/**
 *
 * Mobile Apps
 */
import MobileApp from '../pages/MobileApp';

/**
 *
 * Statistics
 *
 */
import RiderSalaryStatistics from '../pages/Statistics/RiderSalary';

/**
 *
 * Setting
 *
 */
import NotificationSetting from '../pages/Settings/Notification';
/**
 *
 * Third Party
 *
 */
import RegisterNewTPShop from '../pages/ThirdParty/RegisterNewTPShop';
import ListAllThirdParties from '../pages/ThirdParty/ListAllThirdParties';
import ShopProfile from '../pages/ThirdParty/ShopProfile';


const authProtectedRoutes = [
  // Dashboard
  { path: '/dashboard', component: Dashboard },
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },

  // City
  { path: '/city/:city_id/:city_code', component: CityDetails },
  {
    path: '/city/:city_id/:city_code/settings/general/add',
    component: AddCitySettings
  },
  {
    path: '/city/:city_id/:city_code/settings/general',
    component: EditCitySettings
  },
  {
    path: '/city/:city_id/:city_code/settings/membership',
    component: MembershipSettings
  },
  {
    path: '/city/:city_id/:city_code/settings/validity',
    component: ValiditySetting
  },
  {
    path: '/city/:city_id/:city_code/settings/locality',
    component: LocalitySettings
  },
  {
    path: '/city/:city_id/:city_code/settings/salary',
    component: RiderSalarySetting
  },
  { path: '/city/:city_id/:city_code/admin', component: CityAdmin },
  { path: '/city/:city_id/:city_code/payments', component: PaymentDetails },
  {
    path: '/city/:city_id/:city_code/notification',
    component: CityNotificationControl
  },

  // Statistics
  {
    path: '/city/:city_id/:city_code/statistics/rider_salary',
    component: RiderSalaryStatistics
  },

  // KD Mobile App
  { path: '/kd/apps', component: MobileApp },

  // KD Notification Setting
  { path: '/kd/notifications', component: NotificationSetting },
  //Third Party
  { path: '/tp/register', component: RegisterNewTPShop },
  { path: '/tp/list', component: ListAllThirdParties },
  { path: '/tp/profile/view/:tp_id', component: ShopProfile },
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login }
];

export { authProtectedRoutes, publicRoutes };
