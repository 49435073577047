import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormGroup,
  Button,
  Label,
  CustomInput,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useParams } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from 'react-loader-spinner';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';
import validator from 'validator';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const PaymentDetails = () => {
  const { city_code, city_id } = useParams();
  const [breadcrumbItems] = useState([
    { title: 'City', link: '/' },
    { title: 'City Details', link: '#' },
    { title: city_code, link: `/city/${city_id}/${city_code}` },
    { title: 'Payment Details', link: '#' },
  ]);
  const [fetchingSetting, setFetchingSetting] = useState(true);
  const [settingInfo, setSettingInfo] = useState(null);
  const [settingUpdating, setSettingUpdating] = useState(false);
  const [fileInputs, setFileInputs] = useState({});
  const [lightBox, setLightBox] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);

  const fileInputChange = (e) => {
    let filename = e.target.files[0].name;
    let extn = filename.split('.').pop();
    const validType = new RegExp('jpg|png|jpeg');
    if (!validType.test(extn)) {
      return (e.target.value = '');
    }
    setFileInputs({ ...fileInputs, [e.target.name]: e.target.files[0] });
    document.getElementById(e.target.name).textContent = e.target.files[0].name;
  };

  const updatePaymentDetails = (request_type) => async (event, values) => {
    setSettingUpdating(true);
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      Object.keys(fileInputs).forEach((key) => {
        formData.append(key, fileInputs[key]);
      });

      setAuthToken();
      const result =
        request_type === 'POST'
          ? await sendRequest.post(`/city/${city_id}/payments`, formData)
          : await sendRequest.put(`/city/${city_id}/payments`, formData);
      setIsSucceed(true);
      setResponseMessage(result.data.message);
      setOpenResponseModal(true);
    } catch (e) {
      setIsSucceed(false);
      setResponseMessage(e?.response?.data?.error);
      setOpenResponseModal(true);
      setSettingUpdating(false);
    }
  };

  async function fetchPaymentDetails() {
    setFetchingSetting(true);

    try {
      setAuthToken();
      const { data } = await sendRequest.get(`/city/${city_id}/payments`);
      if (data.payment_details) {
        setSettingInfo(data.payment_details);
      }
    } catch (e) {
      console.log(e);
    }

    setFetchingSetting(false);
  }

  useEffect(() => {
    fetchPaymentDetails();
    // eslint-disable-next-line
  }, []);

  const validatePhoneNumber = async (value, ctx, input, cb) => {
    if (!validator.isMobilePhone(value, 'en-IN')) {
      return cb('Please enter a valid Indian Mobile Number');
    }
    return cb(true);
  };

  return (
    <React.Fragment>
      {lightBox && (
        <Lightbox
          mainSrc={settingInfo?.[lightBox.document_name]?.public_url}
          enableZoom={false}
          imageCaption={lightBox.caption}
          onCloseRequest={() => setLightBox(null)}
        />
      )}
      {openResponseModal && (
        <SweetAlert
          success={isSucceed}
          error={!isSucceed}
          title={isSucceed ? 'Updated' : 'Error'}
          onConfirm={() => {
            setOpenResponseModal(false);
            if (isSucceed) {
              fetchPaymentDetails();
            }
          }}
        >
          {responseMessage}
        </SweetAlert>
      )}
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Payment Details' breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className='needs-validation'
                    onValidSubmit={
                      !settingInfo ? updatePaymentDetails('POST') : updatePaymentDetails('PUT')
                    }
                  >
                    <Row>
                      {fetchingSetting ? (
                        <Col md={12}>
                          <div className='text-center'>
                            <Loader type='TailSpin' color='#d3932c' height={80} width={80} />
                          </div>
                        </Col>
                      ) : (
                        <Col xs={12}>
                          <Row>
                            <Col md={6}>
                              <h5 className='text-capitalize mb-3'>Account Information</h5>
                            </Col>
                            <Col md={6}></Col>
                          </Row>
                          <Row>
                            <>
                              <Col md='4'>
                                <FormGroup>
                                  <Label>Account Name</Label>
                                  <AvField
                                    name='account_name'
                                    placeholder='Enter Payment Account Name'
                                    type='text'
                                    className='form-control'
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Account Name is required.',
                                      },
                                    }}
                                    disabled={settingUpdating}
                                    value={settingInfo?.account_name || ''}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md='4'>
                                <FormGroup>
                                  <Label>Phone Number</Label>
                                  <AvField
                                    name='phone_number'
                                    placeholder='Enter Phone Number'
                                    type='text'
                                    className='form-control'
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Phone Number is required',
                                      },
                                      minLength: {
                                        value: 10,
                                        errorMessage: 'Phone Number must be 10 digits',
                                      },
                                      maxLength: {
                                        value: 10,
                                        errorMessage: 'Phone Number must be 10 digits',
                                      },
                                      async: validatePhoneNumber,
                                    }}
                                    disabled={settingUpdating}
                                    value={settingInfo?.phone_number || ''}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md='4'>
                                <FormGroup>
                                  <Label>UPI ID</Label>
                                  <AvField
                                    name='upi_id'
                                    placeholder='Enter UPI ID'
                                    type='text'
                                    className='form-control'
                                    disabled={settingUpdating}
                                    value={settingInfo?.upi_id || ''}
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          </Row>

                          <hr className='my-3' />
                          <Row>
                            <Col md='6'>
                              <FormGroup>
                                <Label>QR Code</Label>
                                <br />
                                <div className='custom-file'>
                                  <CustomInput
                                    onChange={fileInputChange}
                                    type='file'
                                    className='custom-file-input'
                                    name='qr_code'
                                    accept='.png,.jpg,.jpeg'
                                    id='qr_code_input'
                                    disabled={settingUpdating}
                                  />
                                  <Label
                                    id='qr_code'
                                    className='custom-file-label'
                                    htmlFor='customFile'
                                  >
                                    Choose file
                                  </Label>
                                </div>
                              </FormGroup>
                            </Col>
                            {settingInfo?.qr_code?.public_url && (
                              <Col>
                                <FormGroup>
                                  <Label>Current QR Code</Label>
                                  <br />
                                  <Button
                                    color='info'
                                    className=' waves-effect waves-light mr-1'
                                    onClick={() =>
                                      setLightBox({
                                        document_name: 'qr_code',
                                        caption: <h5 className='text-light'>QR Code</h5>,
                                      })
                                    }
                                  >
                                    View Code
                                  </Button>
                                </FormGroup>
                              </Col>
                            )}
                          </Row>

                          <hr className='my-3' />
                          <Button
                            type='submit'
                            color='success'
                            className=' waves-effect waves-light mr-1 float-right'
                            disabled={settingUpdating}
                          >
                            {settingUpdating
                              ? 'Updating...'
                              : !settingInfo
                              ? 'Add Details'
                              : 'Update Details'}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PaymentDetails;
